import {DatePicker, Form, Select, Switch} from 'antd';
import styles from './viewOrders.module.scss';
import {stockOrderStatus} from 'utils/mock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useQuery} from '@apollo/client';
import {GET_ALL_STARTUPS} from 'graphql/queries/startupCompanies';
import {UserSelectField} from 'components/userSelectField/UserSelectField';
import {useEffect} from 'react';
import moment from 'moment';
import {GET_ALL_COLLECTIONS} from 'graphql/queries/collections';

export const OrderFilter = ({
  handleFilter,
  loading,
  user,
  setUser,
  activeTab,
  handleClose,
  reset,
  isExport,
}) => {
  const [form] = Form.useForm();

  const {data: getAllCompanies} = useQuery(GET_ALL_STARTUPS);
  const {data: getAllCollections} = useQuery(GET_ALL_COLLECTIONS, {
    variables: {
      filters: {
        all: true,
      },
    },
  });

  const sortStatus = ['OPEN', 'PARTIALLY_FILLED'];

  useEffect(() => {
    form.resetFields();
  }, [activeTab]);

  const Fields = (
    <>
      {!['PENDING', 'FILLED'].includes(activeTab) && !isExport && (
        <Form.Item
          className={isExport ? styles.smallWidth : ''}
          label="Status"
          name="status">
          <Select allowClear>
            {stockOrderStatus
              .filter(data =>
                activeTab === 'ACTIVE'
                  ? sortStatus.includes(data.key)
                  : !sortStatus.includes(data.key),
              )
              ?.map(data => (
                <Select.Option key={data.key}>{data.name}</Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}

      <div className={styles.price}>
        <Form.Item name="collection" label="Collection">
          <Switch />
        </Form.Item>
        <Form.Item
          className={styles.collectionWidth}
          name="collectionId"
          label="Select Collection">
          <Select optionFilterProp="label" allowClear showSearch>
            {getAllCollections?.adminGetAllCollections?.data?.collections?.map(
              collection => (
                <Select.Option label={collection?.name} key={collection?.id}>
                  <div className="all-data">
                    <img src={collection?.iconUrl} alt="" />
                    <span>{collection?.name}</span>
                  </div>
                </Select.Option>
              ),
            )}
          </Select>
        </Form.Item>
      </div>
      <div className={styles.price}>
        <Form.Item
          className={styles.smallWidth}
          name="direction"
          label="Direction">
          <Select allowClear>
            <Select.Option key="BUY">BUY</Select.Option>
            <Select.Option key="SELL">SELL</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item className={styles.smallWidth} name="type" label="Order Type">
          <Select allowClear>
            <Select.Option key="LIMIT">Limit</Select.Option>
            <Select.Option key="MARKET">Market</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <div className={styles.price}>
        <Form.Item
          className={styles.smallWidth}
          name="userId"
          label="Search & Select User">
          <UserSelectField
            users={user}
            setUsers={setUser}
            defaultUsers={user}
            mode="single"
          />
        </Form.Item>
        <Form.Item
          className={styles.smallWidth}
          name="companyId"
          label="Company">
          <Select allowClear showSearch>
            {getAllCompanies?.getAllCompanies?.map(company => (
              <Select.Option key={`${company.name}~${company.id}`}>
                <div className="all-data">
                  <img src={company.logoImgURL} alt="" />
                  <span>{company.name}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className={styles.price}>
        <Form.Item
          name="startDate"
          className={styles.smallWidth}
          label="Start Date">
          <DatePicker
            allowClear
            format={'YYYY-MM-DD HH:mm:ss'}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
          />
        </Form.Item>
        <Form.Item name="endDate" label="End Date">
          <DatePicker
            allowClear
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
      </div>

      {(['EXPIRE', 'PAST'].includes(activeTab) || isExport) && (
        <div className={styles.price}>
          <Form.Item name="startSettlementDate" label="Start Settlement Date">
            <DatePicker
              allowClear
              format={'YYYY-MM-DD HH:mm:ss'}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
            />
          </Form.Item>
          <Form.Item name="endSettlementDate" label="End Settlement Date">
            <DatePicker
              allowClear
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
        </div>
      )}
      <div className={styles.price}>
        <Form.Item name="startFillDate" label="Start Fill Date">
          <DatePicker
            allowClear
            format={'YYYY-MM-DD HH:mm:ss'}
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
          />
        </Form.Item>
        <Form.Item name="endFillDate" label="End Fill Date">
          <DatePicker
            allowClear
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
          />
        </Form.Item>
      </div>
      {(['EXPIRE'].includes(activeTab) || isExport) && (
        <div className={styles.price}>
          <Form.Item name="startExpiryDate" label="Start Expiry Date">
            <DatePicker
              allowClear
              format={'YYYY-MM-DD HH:mm:ss'}
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
            />
          </Form.Item>
          <Form.Item name="endExpiryDate" label="End Expiry Date">
            <DatePicker
              allowClear
              showTime={{
                defaultValue: moment('00:00', 'HH:mm:ss'),
              }}
              format={'YYYY-MM-DD HH:mm:ss'}
            />
          </Form.Item>
        </div>
      )}
    </>
  );

  return (
    <div className={styles.order}>
      {!isExport && (
        <div className={styles.header}>
          <h1>Sort Orders</h1>
          <p role="button" onClick={handleClose}>
            X
          </p>
        </div>
      )}
      {isExport ? (
        Fields
      ) : (
        <Form
          onFinish={values => {
            handleFilter(values);
            reset();
          }}
          form={form}
          layout="vertical">
          {Fields}
          {!isExport && (
            <SubmitButton disabled={loading} label="Apply Changes" />
          )}
        </Form>
      )}
    </div>
  );
};

import {useQuery} from '@apollo/client';
import {Button} from '@dabafinance/react-components';
import {Dropdown, Menu, Table, Tag} from 'antd';
import {GET_ALL_ORDERS} from 'graphql/queries/stocks';
import {useNavigate, useParams} from 'react-router-dom';
import styles from './transactions.module.scss';
import dotsIcon from 'icons/dots.svg';

import {useState} from 'react';
import {
  FilledOrder,
  OpenOrder,
  OrderHistory,
  RejectOrder,
} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';

const UserStocks = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [orderId, setOrderId] = useState('');
  const [orderModal, setOrderModal] = useState('');
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState({});
  const {id} = useParams();
  const {loading, data, refetch} = useQuery(GET_ALL_ORDERS, {
    variables: {
      input: {
        page: currentPage,
        'paginate': true,
        'limit': 10,
        'newFirst': true,
        userId: id,
      },
    },
  });

  const handleTab = (tab, id) => {
    if (['FILLED', 'PENDING', 'HISTORY'].includes(tab)) {
      setOrderModal(tab);
      setOrderDetails(id);
    } else if (tab === 'REJECT') {
      setOrderModal(tab);
    } else {
      navigate(`/dashboard/stocks/settle-order/${id}`);
    }
    setOrderId(id);
  };

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => prevPage + 1);
  };

  const columns = [
    {
      title: 'Ticker',
      key: 'ticker',
      dataIndex: 'ticker',
      render: (text, render) => <span>{render?.company?.ticker}</span>,
    },
    {
      title: 'Direction',
      key: 'direction',
      dataIndex: 'direction',
    },
    {
      title: 'Currency',
      key: 'assetCurrency',
      dataIndex: 'assetCurrency',
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'price',
      render: (text, render) => <span>{render?.price?.toLocaleString()}</span>,
    },
    {
      title: 'Quantity',
      key: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, render) => (
        <Tag
          color={
            {
              'FILLED': 'green',
              'PENDING': 'orange',
              'ERROR': 'red',
              'REJECTED': 'red',
              'CANCELLED': 'red',
              'SETTLED': 'cyan',
              'PARTIALLY_FILLED': 'yellow',
            }[render?.status] || 'blue'
          }
          key={render.id}>
          {render.status}
        </Tag>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      render: (text, render) => (
        <Dropdown
          trigger="hover"
          overlay={
            <Menu>
              {['OPEN', 'PARTIALLY_FILLED'].includes(render?.status) ? (
                <>
                  <Menu.Item
                    key="1"
                    onClick={() => handleTab('REJECT', render.id)}>
                    <div>
                      <span>Reject Order</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item
                    key="2"
                    onClick={e => handleTab('SETTLE', render.id, e)}>
                    <div>
                      <span>Fill Order</span>
                    </div>
                  </Menu.Item>
                </>
              ) : render?.status === 'FILLED' ? (
                <Menu.Item
                  key="2"
                  onClick={e => handleTab('FILLED', render, e)}>
                  <div>
                    <span>Settle Order</span>
                  </div>
                </Menu.Item>
              ) : render?.status === 'PENDING' ? (
                <Menu.Item onClick={e => handleTab('PENDING', render.id, e)}>
                  Open Order
                </Menu.Item>
              ) : (
                <></>
              )}
              {render?.status !== 'PENDING' && (
                <Menu.Item
                  onClick={e => handleTab('HISTORY', render?.history, e)}
                  key="5">
                  View History
                </Menu.Item>
              )}
            </Menu>
          }>
          <img src={dotsIcon} onClick={e => e.stopPropagation()} />
        </Dropdown>
      ),
    },
  ];

  return (
    <div>
      <div className={styles['nav-buttons']}>
        <Button
          onClick={handlePreviousPage}
          label="Previous"
          type="secondary"
          disabled={!data?.getOrders?.pagination?.hasPrevPage || loading}
          className={styles.button}
        />
        <div className={styles['page-number']}>{currentPage}</div>
        <Button
          onClick={handleNextPage}
          label="Next"
          disabled={!data?.getOrders?.pagination?.hasNextPage || loading}
          className={styles.button}
        />
      </div>
      <Table
        columns={columns}
        dataSource={data?.getOrders?.data}
        loading={loading}
        pagination={false}
      />
      <RejectOrder
        orderId={orderId}
        orderModal={orderModal === 'REJECT'}
        setOrderModal={setOrderModal}
        refetch={refetch}
        page={currentPage}
      />
      <FilledOrder
        orderModal={orderModal === 'FILLED'}
        setOrderModal={setOrderModal}
        orderDetails={orderDetails}
        refetch={refetch}
        page={currentPage}
      />
      <OpenOrder
        orderModal={orderModal === 'PENDING'}
        setOrderModal={setOrderModal}
        orderId={orderId}
        refetch={refetch}
        page={currentPage}
      />
      <OrderHistory
        orderModal={orderModal === 'HISTORY'}
        setOrderModal={setOrderModal}
        history={orderDetails}
      />
    </div>
  );
};

export default UserStocks;

import {gql} from '@apollo/client';

export const GET_ALL_USER_INVITES = gql`
  query GetAllUsersInvites {
    getAllUsersInvites {
      totalInvitedUsers
      totalInvitedUserInvested
      totalPayout
      users {
        user {
          id
          email
          firstName
          lastName
          imageUrl
        }
        count
        userTotalRewardEarned
      }
    }
  }
`;

export const GET_USER_INVITES = gql`
  query GetUserInvites($user: ID!) {
    getUserInvites(user: $user) {
      ... on Error {
        message
        statusCode
      }
      ... on UserReferralType {
        userReferral {
          id
          user {
            id
            firstName
            username
            firstName
            lastName
            imageUrl
          }
          invitedUser {
            id
            firstName
            lastName
            username
            imageUrl
          }
          invitedDate
          referralTokenEarned
          investedAmount
        }
      }
    }
  }
`;

import {Outlet, useNavigate} from 'react-router-dom';
import {Button} from '@dabafinance/react-components';
import styles from './rewardSchemes.module.scss';
import PercentageCard from './rewardSchemeCards/PercentageCard';
import {useState} from 'react';
import {GET_ALL_REWARD_SCHEMES} from 'graphql/queries/rewardScheme';
import {useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import CustomLink from 'components/customLink/customLink';
import {useSelector} from 'react-redux';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';

const RewardSchemeManagement = () => {
  const navigate = useNavigate();
  const [stats, setStats] = useState([]);

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.RewardScheme),
  );

  const {loading} = useQuery(GET_ALL_REWARD_SCHEMES, {
    onCompleted: data => setStats(data?.getAllRewardSchemes?.data?.stats),
    onError: error => toast.error(error),
  });

  return (
    <div className={styles['investor-container']}>
      <section className={styles['percentage-tab']}>
        <PercentageCard
          amount={stats?.totalActiveRewardPrograms || 0}
          label="Active Reward Scheme"
          loading={loading}
          active
          postive
        />
        <PercentageCard
          amount={stats?.totalNewUsersFromRewards || 0}
          label="Total New Users from Reward"
          loading={loading}
          postive
        />
        {stats?.totalDepositGeneratedFromRewards?.map((item, index) => (
          <PercentageCard
            key={index}
            amount={item?.totalAmount || 0}
            loading={loading}
            label={`Total Amount Generated from Reward in ${item?.currency}`}
          />
        ))}
      </section>
      <div className={styles.header}>
        <h1>Reward Schemes</h1>
        <div>
          <Button
            disabled={!tabOperations?.create}
            onClick={() => navigate('/dashboard/reward-schemes/new')}
            label="New Reward Scheme"
            className={styles.button}
          />
          <Button
            onClick={() =>
              navigate('/dashboard/reward-schemes/new-user-reward')
            }
            disabled={!tabOperations?.update}
            label="Issue Out Reward"
            className={styles.button}
          />
        </div>
      </div>
      <section className={styles.section}>
        <nav className={styles['investor-toggler']}>
          <CustomLink
            to={'/dashboard/reward-schemes'}
            name="All Reward Schemes"
          />
          <CustomLink
            to={'/dashboard/reward-schemes/referral'}
            name="Referral Reward Scheme"
          />
          <CustomLink
            to={'/dashboard/reward-schemes/transactional'}
            name="Transactional Reward Scheme"
          />
          <CustomLink
            to={'/dashboard/reward-schemes/expired'}
            name="Expired Reward Scheme"
          />
          <CustomLink
            to={'/dashboard/reward-schemes/users'}
            name="All User Rewards"
          />
        </nav>
      </section>
      <section>
        <Outlet />
      </section>
    </div>
  );
};

export {RewardSchemeManagement};

import {useState} from 'react';
import styles from './transferDetails.module.scss';

const WireTransferDetails = ({data}) => {
  const bankInfo = data?.bankInformation;
  const mobileMoneyInfo = data?.mobileMoneyInformation;
  const [viewReceipt, setViewReceipt] = useState(false);

  const openImageInNewTab = () => {
    const imageUrl =
      bankInfo?.wireTransferReceiptImage ||
      bankInfo?.bankTransferInstructionImageURL;
    window.open(imageUrl, '_blank');
  };

  return (
    <div className={styles.root}>
      {['MOBILE_MONEY', 'HUB2_MOBILE_MONEY'].includes(data?.paymentMethod) ? (
        <>
          <div className={styles.top}>
            <h1>Mobile Money Details</h1>
          </div>
          <div className={styles.contents}>
            <div className={styles.content}>
              <h3>Beneficiary Name</h3>
              <p>{mobileMoneyInfo?.beneficiaryName ?? '--'}</p>
            </div>
            <div className={styles.content}>
              <h3>Country ISO</h3>
              <p>{mobileMoneyInfo?.countryISO ?? '--'}</p>
            </div>
            <div className={styles.content}>
              <h3>Phone Number</h3>
              <p>{mobileMoneyInfo?.phoneNumber ?? '--'}</p>
            </div>
            <div className={styles.content}>
              <h3>Mobile Money Network</h3>
              <p>{mobileMoneyInfo?.mobileMoneyNetwork ?? '--'}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.top}>
            <h1>User Bank Details</h1>
          </div>
          {viewReceipt &&
          (bankInfo?.wireTransferReceiptImage ||
            bankInfo?.bankTransferInstructionImageURL) ? (
            <div className={styles.receipt}>
              <img
                src={
                  bankInfo?.wireTransferReceiptImage ||
                  bankInfo?.bankTransferInstructionImageURL
                }
                alt=""
              />
            </div>
          ) : (
            <div className={styles.contents}>
              <div className={styles.content}>
                <h3>Account Name</h3>
                <p>{bankInfo?.accountName ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Account Number</h3>
                <p>{bankInfo?.accountNumber ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Bank Name</h3>
                <p>{bankInfo?.bankName ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Bank Country</h3>
                <p>{bankInfo?.bankCountry ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Routing Number</h3>
                <p>{bankInfo?.routingNumber ?? '--'}</p>
              </div>
              <div className={styles.content}>
                <h3>Swift Code</h3>
                <p>{bankInfo?.swiftCode ?? '--'}</p>
              </div>
            </div>
          )}
          <div className={styles.modalButtons}>
            {bankInfo?.wireTransferReceiptImage ? (
              <button
                className={styles.button}
                onClick={() => setViewReceipt(!viewReceipt)}>
                View {viewReceipt ? 'Bank Details' : 'Receipt'}
              </button>
            ) : bankInfo?.bankTransferInstructionImageURL ? (
              <button
                className={styles.button}
                onClick={() => setViewReceipt(!viewReceipt)}>
                View {viewReceipt ? 'Bank Details' : 'Bank Withdrawal Details'}
              </button>
            ) : (
              <></>
            )}
            {viewReceipt &&
              (bankInfo?.wireTransferReceiptImage ||
                bankInfo?.bankTransferInstructionImageURL) && (
                <button className={styles.button} onClick={openImageInNewTab}>
                  Preview Image
                </button>
              )}
          </div>
        </>
      )}
    </div>
  );
};

export default WireTransferDetails;

import {useEffect, useState} from 'react';
import {
  resizeImageFile,
  UploadDocumentToBucket,
} from '../legalDocument/uploadDocument';
import AnalogCard from './AnalogCard';
import plusIcon from 'images/plus-circle.png';
import styles from './campaignAnalog.module.scss';
import FadeEffect from 'components/animation/fadeEffect';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {analogs} from 'redux/store/campaign';

const CampaignAnalog = () => {
  const [analogValues, setAnalogValues] = useState([]);
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);
  const {id, companyId, slug} = useParams();
  const [disableButton, setDisableButton] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const campaign = useSelector(state => state.campaign.campaign);

  useEffect(() => {
    if (analogValues.length) {
      analogValues?.map(data => {
        const isValid =
          !data.name ||
          !data.description ||
          !data.valuation ||
          !data.imageUrl ||
          !data.type;
        return setDisableButton(isValid);
      });
    } else {
      setDisableButton(false);
    }
  }, [analogValues]);

  useEffect(() => {
    if (campaign?.analogs) {
      setAnalogValues(
        campaign?.analogs?.map(data => ({
          name: data?.name,
          type: data?.type,
          description: data?.description,
          imageUrl: data?.imageUrl,
          previewUrl: data?.previewUrl,
          valuation: data?.valuation,
        })),
      );
    }
  }, [campaign?.analogs]);

  const handleAddAnalog = () => {
    setAnalogValues([
      ...analogValues,
      {
        name: '',
        type: '',
        description: '',
        imageUrl: '',
        previewUrl: '',
        valuation: 0,
      },
    ]);
  };

  const handleChangeImage = async (i, {file}) => {
    const temp = file.originFileObj;
    const newFormValues = [...analogValues];
    const resizedFile = await resizeImageFile(temp);
    setLoadingImageUpload(true);
    const res = await UploadDocumentToBucket(resizedFile);
    setLoadingImageUpload(false);
    newFormValues[i].previewUrl = res;
    newFormValues[i].imageUrl = res;
    setAnalogValues(newFormValues);
  };

  const handleChangeCompanyType = (index, event) => {
    const newFormValues = [...analogValues];
    newFormValues[index].type = event;
    setAnalogValues(newFormValues);
  };

  const handleDeleteAnalog = id => {
    const filteredFormValue = analogValues.filter(
      (data, index) => index !== id,
    );
    setAnalogValues(filteredFormValue);
  };

  const handleChangeNumberFields = (i, e) => {
    let newFormValues = [...analogValues];
    newFormValues[i].valuation = e;
    setAnalogValues(newFormValues);
  };

  const handleChangeFields = (i, e) => {
    const newFormValues = [...analogValues];
    newFormValues[i][e.target.name] = e.target.value;
    setAnalogValues(newFormValues);
  };

  const handleDeleteImage = i => {
    const newFormValues = [...analogValues];
    newFormValues[i].previewUrl = '';
    newFormValues[i].imageUrl = '';
    setAnalogValues(newFormValues);
  };

  const handleSave = () => {
    dispatch(
      analogs({
        analogs: analogValues,
      }),
    );
    if (slug) {
      navigate(
        `/dashboard/campaigns/${slug}/new/${id}/daba-score/${companyId}`,
      );
    } else {
      navigate(`/dashboard/campaigns/new/${id}/daba-score/${companyId}`);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <h2>Campaign Analogs</h2>
      </div>
      <div>
        {analogValues?.map((data, index) => (
          // eslint-disable-next-line react/jsx-key
          <FadeEffect>
            <AnalogCard
              id={index}
              name={data.name}
              type={data.type}
              valuation={data.valuation}
              imgUrl={data.imageUrl}
              loadingImageUpload={loadingImageUpload}
              previewImage={data.previewUrl}
              description={data.description}
              disableButton={disableButton}
              handleNumberChange={e => handleChangeNumberFields(index, e)}
              handleDeleteAnalog={() => handleDeleteAnalog(index)}
              handleDeleteImage={() => handleDeleteImage(index)}
              handleChange={e => handleChangeFields(index, e)}
              handleChangeImage={e => handleChangeImage(index, e)}
              handleChangeCompanyType={e => handleChangeCompanyType(index, e)}
            />
          </FadeEffect>
        ))}
      </div>
      {disableButton && (
        <span className={styles['warning']}>
          Please Note that all fields are required
        </span>
      )}

      <div onClick={handleAddAnalog} className={styles['add-button']}>
        <img src={plusIcon} alt="" />
        <h1>Add Analog</h1>
      </div>
      <SubmitButton
        disabled={disableButton}
        label="Save & Continue"
        handleClick={handleSave}
      />
    </div>
  );
};

export default CampaignAnalog;

import {
  HomeIcon,
  OppIcon,
  AllIcon,
  GroupsIcon,
  FriendIcon,
  BuildingIcon,
  HeadphoneIcon,
  Plans,
  NewsIcon,
  InterestIcon,
  BagIcon,
  BrainIcon,
  Faq,
  Complaint,
  NotificationIcon,
  SettingsIcon,
  Bookmark,
  CryptoIcon,
  UserIcon,
  Stocks,
  Marketing,
  WalletIcon,
  SavingsOverview,
  SavingsProductIcon,
} from '../icons';

export const chartConfig = date => {
  return {
    showPoints: false,
    customDataParse: {
      xAxisKey: 'date',
      yAxisKey: 'amount',
    },
    xAxisConfig: {
      display: true,

      type: 'time',
      time: {
        unit: 'month',
        displayFormats: {
          quarter: 'WW YY',
        },
      },
    },
    yAxisConfig: {
      display: true,
      position: 'left',
    },
  };
};

export const barChartDataset = (data, labels) => {
  return {
    labels,
    datasets: [
      {
        label: 'Yearly Users Chart',
        backgroundColor: '#D8CDFC',
        borderColor: '#D8CDFC',
        borderWidth: 0,
        data,
        maxBarThickness: 40,
        categoryPercentage: 0.2,
        barPercentage: 0.5,
      },
    ],
  };
};

export const usersLineChartControls = [
  {label: 'DAY', format: 'L', title: 'DAILY'},
  {label: 'WEEK', format: 'WW', title: 'WEEKLY'},
  {label: 'MONTH', format: 'MMMM YYYY', title: 'MONTHLY'},
  {label: 'YEAR', format: 'YYYY', title: 'YEARLY'},
];

export const notificationLanguages = [
  {label: 'English', key: 'EN'},
  {label: 'French', key: 'FR'},
];

export const downloadUsersHeader = [
  {label: 'First Name', key: 'firstName'},
  {label: 'Last Name', key: 'lastName'},
  {label: 'Email', key: 'email'},
  {label: 'Phone Number', key: 'phoneNumber'},
  {label: 'Year of Birth', key: 'dateOfBirth'},
  {label: 'Accredited', key: 'isAccredited'},
  {label: 'Profile Completion(%)', key: 'completionPercentage'},
  {label: 'Country of Residence', key: 'country'},
  {label: 'Country of Citizenship', key: 'countryOfCitizenship'},
  {label: 'Annual Net Income', key: 'netIncome'},
  {label: 'Employment Status', key: 'employmentStatus'},
  {label: 'Estimated Net Worth', key: 'netWorth'},
  {label: 'Investment Experience', key: 'investmentExperience'},
  {label: 'When would you need to take out your Investment?', key: 'takeOut'},
  {label: 'How do you see yourself investing?', key: 'howInvest'},
  {label: 'User Topics', key: 'topics'},
  {label: 'User Transactions', key: 'transactions'},
  {label: 'User Reservations', key: 'reservations'},

  {label: 'Country of Interests', key: 'countryInterests'},

  {label: 'Device', key: 'device'},
  {label: 'Last Session Date Time', key: 'time'},

  {label: 'Current Version', key: 'appVersion'},
  {label: 'Verification Method', key: 'verificationMethod'},
  {label: 'Date Joined', key: 'joined'},
];

export const notificationOptions = [
  {label: 'MONDAY', value: 'Monday'},
  {label: 'TUESDAY', value: 'Tuesday'},
  {label: 'WEDNESDAY', value: 'Wednesday'},
  {label: 'THURSDAY', value: 'Thursday'},
  {label: 'FRIDAY', value: 'Friday'},
  {label: 'SATURDAY', value: 'Saturday'},
  {label: 'SUNDAY', value: 'Sunday'},
];

export const notificationFilterOptions = [
  {value: 'EITHER', label: 'Either'},
  {value: 'YES', label: 'Yes'},
  {value: 'NO', label: 'No'},
];

export const usersFilterEnumOptions = [
  {value: 'EITHER', label: 'Either'},
  {value: 'TRUE', label: 'Yes'},
  {value: 'FALSE', label: 'No'},
];

export const notificationLastVisitOptions = [
  {label: 'Either', value: 'EITHER'},
  {label: 'Older than a week', value: 'OLDER_THAN_A_WEEK'},
  {label: 'Older than a month', value: 'OLDER_THAN_A_MONTH'},
  {label: 'Older than a three months', value: 'OLDER_THAN_THREE_MONTHS'},
  {label: 'Older than a six month', value: 'OLDER_THAN_SIX_MONTH'},
];

export const notificationTypes = [
  {label: 'ANNOUNCEMENTS', value: 'Announcements'},
  {label: 'HOMEPAGE', value: 'Homepage'},
  {label: 'STOCKS', value: 'Stocks'},
  {label: 'SINGLE_STOCK', value: 'Single Stock'},

  {label: 'OPPORTUNITY_PAGE', value: 'Opportunity Page'},
  {label: 'INTELLIGENCE_PAGE', value: 'Intelligence Page'},
  {label: 'GROUPS_PAGE', value: 'Groups Page'},
  {label: 'OPPORTUNITY_BY_ID', value: 'Single Opportunity'},
  {label: 'REPORT_BY_ID', value: 'Single Report'},
  {label: 'GROUP_BY_ID', value: 'Single Group'},
  {label: 'COMPANY_BY_ID', value: 'Single Company'},
  {label: 'DIVIDENDS_ANNOUNCEMENT', value: 'Dividend Announcement'},
  {
    label: 'EXTERNAL_LINK',
    value: 'External Link',
  },
];

export const emailNotificationTypes = [
  {label: 'ANNOUNCEMENTS', value: 'Announcements'},
  {label: 'GENERAL', value: 'General'},
  {label: 'DIVIDENDS_ANNOUNCEMENT', value: 'Dividend Announcement'},
];

export const assetClassEnum = [
  {label: 'IPO', value: 'IPO'},
  {label: 'STOCK', value: 'STOCK'},
  {label: 'VENTURE', value: 'VENTURE'},
];

export const audienceTypes = [
  {value: 'ALL_USERS', label: 'All Users'},
  {value: 'USERS', label: 'User(s)'},
  {value: 'INTERNAL_TEAM_MEMBERS', label: 'Internal Team Members'},
  {value: 'USERS_BY_GROUP', label: 'Users in Group(s)'},
  {value: 'INVESTORS_IN_A_CAMPAIGN', label: 'Investors in a Campaign'},
  {value: 'INVESTORS_WITH_RESERVATIONS', label: 'Investors with Reservations'},
];

export const paymentMethodOptions = [
  {value: 'CREDITCARD', label: 'Credit Card'},
  {value: 'WIRE_AUTO', label: 'Wire Auto'},
  {value: 'ACH_MANUAL', label: 'ACH Manual'},
  {value: 'WIRE_MANUAL', label: 'Wire Manual'},
  {value: 'CRYPTO_MANUAL', label: 'Crypto Manual'},
  {value: 'CRYPTO_AUTO', label: 'Crypto Auto'},
  {value: 'ACH_AUTO', label: 'ACH Auto'},
];

export const noOfEmployeesOptions = [
  {value: 'BETWEEN_ZERO_AND_ONE', label: 'Between 0 & 1'},
  {value: 'BETWEEN_TWO_AND_TEN', label: 'Between 2 & 10'},
  {value: 'BETWEEN_ELEVEN_AND_FIFTY', label: 'Between 11 & 50'},
  {value: 'BETWEEN_FIFTY_ONE_AND_TWO_HUNDRED', label: 'Between 51 & 200'},
  {value: 'BETWEEN_TWO_HUNDRED_AND_FIVE_HUNDRED', label: 'Between 200 & 500'},
  {
    value: 'BETWEEN_FIVE_HUNDRED_AND_ONE_AND_ONE_THOUSAND',
    label: 'Between 501 & 1,000',
  },
  {
    value: 'BETWEEN_ONE_THOUSAND_AND_FIVE_THOUSAND',
    label: 'Between 1,000 & 5,000',
  },
  {
    value: 'BETWEEN_FIVE_THOUSAND_AND_TEN_THOUSAND',
    label: 'Between 5,000 & 10,000',
  },
  {value: 'MORE_THAN_TEN_THOUSAND', label: 'More than 10,000'},
];

export const transactionStatusOptions = [
  {value: 'REFUND_PENDING', label: 'Refund Pending'},
  {value: 'REFUND_SUCCESSFUL', label: 'Refund Successful'},
  {value: 'CREATED', label: 'Created'},
  {value: 'SETTLED', label: 'Settled'},
  {value: 'FAILED', label: 'Failed'},
  {value: 'FUNDED', label: 'Funded'},
  {value: 'VOIDED', label: 'Voided'},
  {value: 'CANCELLED', label: 'Canceled'},
];

export const transactionTypes = [
  {value: 'STRATEGY', label: 'Strategy'},
  {value: 'VENTURE', label: 'Venture'},
  {value: 'IPO', label: 'IPO'},
  {value: 'STOCK', label: 'Stock'},
  {value: 'REFUND', label: 'Refund'},
];

export const ADMIN_USER_TYPES = [
  {
    key: 'ALL',
    label: 'All Users',
    filterProperty: 'kycStatus',
    filterPropertyValue: undefined,
    downloadName: 'All Users',
    labelTitle: 'All Users',
  },
  {
    key: 'UNVERIFIED',
    filterProperty: 'kycStatus',
    filterPropertyValue: 'NOT_STARTED',
    label: 'Unverified',
    downloadName: 'List of Unverified KYC Users',
    labelTitle: 'Unverified KYC Users',
  },
  {
    key: 'PENDING',
    filterProperty: 'kycStatus',
    filterPropertyValue: 'PENDING',
    label: 'Pending',
    downloadName: 'List of Users with Pending KYC',
    labelTitle: 'Pending KYC Users',
  },
  {
    key: 'PENDING VERIFICATION',
    filterProperty: 'kycStatus',
    filterPropertyValue: 'PENDING_VERIFICATION',
    label: 'Pending Verification',
    downloadName: 'List of Users with Pending KYC Verification',
    labelTitle: 'Pending Verified Users',
  },
  {
    key: 'VERIFIED',
    filterProperty: 'kycStatus',
    filterPropertyValue: 'APPROVED',
    label: 'Approved',
    downloadName: 'List of Users with Verified KYC',
    labelTitle: 'Approved KYC Users',
  },
  {
    key: 'ACCREDITED',
    filterProperty: 'isAccredited',
    filterPropertyValue: 'ACCREDITED',
    label: 'Accredited',
    downloadName: 'List of Accredited Users',
    labelTitle: 'Accredited Users',
  },
  {
    key: 'FAILED',
    filterProperty: 'kycStatus',
    filterPropertyValue: 'REJECTED',
    label: 'Failed',
    downloadName: 'List of Users with failed KYC Validation',
    labelTitle: 'Failed Verification Users',
  },
  {
    key: 'DISABLED',
    filterProperty: 'deleted',
    filterPropertyValue: true,
    label: 'Disabled',
    downloadName: 'List of Disabled Users',
    labelTitle: 'Disabled Users',
  },
];

export const notificationChannels = [
  'PUSH',
  'SMS',
  'IN_APP',
  'EMAIL',
  'WHATSAPP',
];

export const CAMPAIGN_TYPES = [
  {
    key: 'DRAFT',
    label: 'Draft Listings',
    route: 'draft',
  },
  {
    key: 'ACTIVE',
    label: 'Active Listings',
    route: 'active',
  },
  {
    key: 'ENDED',
    label: 'Ended Listings',
    route: 'ended',
  },
];

export const ORDER_TRADE_STATUS = [
  {
    key: 'REFUND_PENDING',
    value: 'Refund Pending',
  },
  {
    key: 'REFUND_SUCCESSFUL',
    value: 'Refund Successful',
  },
  {
    key: 'CREATED',
    value: 'Created',
  },
  {
    key: 'SETTLED',
    value: 'Settled',
  },
  {
    key: 'FAILED',
    value: 'Failed',
  },
  {
    key: 'FUNDED',
    value: 'Funded',
  },
  {
    key: 'VOIDED',
    value: 'VOIDED',
  },
  {
    key: 'CANCELED',
    value: 'Canceled',
  },
];
export const FADE_RIGHT_ANIMATION_VARIANTS = {
  hidden: {opacity: 0, y: -10},
  show: {opacity: 1, y: 0, transition: {type: 'spring'}},
};

export const supportedLanguages = [
  {value: 'EN', label: 'English'},
  {value: 'FR', label: 'French'},
];

export const QUILL_MODULES = {
  toolbar: [
    [{header: '1'}, {header: '2'}, {header: [3, 4, 5, 6]}, {font: []}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{list: 'ordered'}, {list: 'bullet'}],
    ['link'],

    ['code-block'],
  ],
};

export const QUILL_FORMATS = [
  'header',
  'size',
  'font',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'link',
  'code-block',
];

export const NavTabsEnum = {
  Overview: 'OVERVIEW',
  Announcements: 'ANNOUNCEMENTS',
  Users: 'USERS',
  UsersNPS: 'USERS_NPS',
  Assets: 'ASSETS',
  keyStatistics: 'KEY_STATISTICS',
  Stocks: 'STOCKS',
  Startups: 'STARTUPS',
  StockListings: 'STOCK_LISTINGS',
  BuySellOrder: 'BUY_SELL_ORDER',
  Listings: 'LISTINGS',
  Topics: 'TOPICS',
  Support: 'SUPPORT',
  Affiliates: 'AFFILIATES',
  FAQ: 'FAQ',
  SAVINGS: 'SAVINGS',
  Report: 'REPORT',
  Management: 'MANAGEMENT',
  Updates: 'INVESTOR_UPDATES',
  News: 'NEWS',
  Groups: 'GROUPS',
  Plans: 'PLANS',
  DocCategory: 'DOC_CATEGORY',
  ListingReports: 'LISTING_REPORTS',
  Dividends: 'DIVIDEND',
  NotificationSchedule: 'NOTIFICATION_SCHEDULE',
  NotificationClass: 'NOTIFICATION_CLASS',
  Wallet: 'WALLET_SETTINGS',
  Swap: 'SWAP_SETTINGS',
  AccessCode: 'ACCESS_CODE',
  RewardScheme: 'REWARD_SCHEME',
  RewardBanks: 'REWARD_BANKS',
  ReferralProgram: 'REFERRAL_PROGRAM',
  UsersReferrals: 'USER_REFERRALS',
  DepositSettings: 'DEPOSIT_SETTINGS',
  PlatformSettings: 'PLATFORM_SETTINGS',
  BankWireDetails: 'BANK_WIRE_DETAILS',
  CryptoDetails: 'CRYPTO_DETAILS',
  Subscription: 'SUBSCRIPTION',
  Collections: 'COLLECTIONS',
};

const navIcons = {
  Overview: <HomeIcon />,
  Announcement: <NotificationIcon />,
  Users: <FriendIcon />,
  UsersNPS: <AllIcon />,
  Assets: <BuildingIcon />,
  Startups: <BuildingIcon />,
  keyStatistics: <BrainIcon />,
  Stocks: <OppIcon />,
  Listings: <NewsIcon />,
  buyAndSell: <Stocks />,
  Topics: <InterestIcon />,
  Support: <HeadphoneIcon />,
  Faq: <Faq />,
  Report: <AllIcon />,
  Management: <BagIcon />,
  DocCategory: <BagIcon />,
  Updates: <OppIcon />,
  News: <BuildingIcon />,
  Affiliates: <Bookmark />,
  Marketing: <Marketing />,
  SavingsOverview: <SavingsOverview />,
  Groups: <GroupsIcon />,
  listingReports: <BagIcon />,
  Plans: <Plans />,
  Notification: <NotificationIcon />,
  Wallet: <WalletIcon />,
  Settings: <SettingsIcon />,
  accessCode: <HomeIcon />,
  referralProgram: <OppIcon />,
  usersReferral: <FriendIcon />,
  depositSettings: <WalletIcon />,
  platformSettings: <Complaint />,
  bankWireDetails: <BuildingIcon />,
  cryptoDetails: <CryptoIcon />,
  subscription: <UserIcon />,
  collections: <Stocks />,
};

export const ADMIN_TABS = {
  Overview: {
    name: 'Overview',
    tab: NavTabsEnum.Overview,
    icon: navIcons.Overview,
    to: '',
  },
  Announcement: {
    name: 'Announcements',
    tab: NavTabsEnum.Announcements,
    icon: navIcons.Announcement,
    to: 'announcements',
  },
  Users: {name: 'Users', tab: 'USERS', icon: navIcons.Users, to: 'users'},
  UsersNPS: {
    name: 'Users NPS',
    tab: NavTabsEnum.UsersNPS,
    icon: navIcons.UsersNPS,
    to: 'nps',
  },
  Assets: {
    name: 'Assets',
    tab: NavTabsEnum.Assets,
    icon: navIcons.Assets,
    inner: {
      Startups: {
        name: 'Startups',
        tab: NavTabsEnum.Startups,
        icon: navIcons.Startups,
        to: 'opportunities/startups',
      },

      keyStatistics: {
        name: 'Key Statistics',
        tab: NavTabsEnum.keyStatistics,
        icon: navIcons.keyStatistics,
        to: 'key-statistics',
      },
      collections: {
        name: 'Collections',
        tab: NavTabsEnum.Collections,
        icon: navIcons.collections,
        to: 'collections',
      },
    },
  },
  Dividends: {
    name: 'Dividends',
    icon: navIcons.Wallet,
    tab: NavTabsEnum.Dividends,
    to: 'dividends/payouts',
  },
  Marketing: {
    name: 'Marketing',
    icon: navIcons.Marketing,
    tab: NavTabsEnum.Dividends,
    inner: {
      Affiliates: {
        name: 'Affiliates',
        tab: NavTabsEnum.Affiliates,
        icon: navIcons.Affiliates,
        to: 'affiliates',
      },
    },
  },
  Stocks: {
    name: 'Stocks',
    icon: navIcons.Stocks,
    tab: NavTabsEnum.Stocks,
    inner: {
      Listings: {
        name: 'Stock Listings',
        tab: NavTabsEnum.StockListings,
        icon: navIcons.Listings,
        to: 'stocks/listings',
      },
      buyAndSell: {
        name: 'Buy/Sell Order',
        tab: NavTabsEnum.BuySellOrder,
        icon: navIcons.buyAndSell,
        to: 'stocks/user-trade',
      },
      DocCategory: {
        name: 'Document Center',
        tab: NavTabsEnum.DocCategory,
        icon: navIcons.DocCategory,
        to: 'startup/document-category',
      },
    },
  },
  Listings: {
    name: 'Listings',
    tab: NavTabsEnum.Listings,
    icon: navIcons.Listings,
    inner: {
      ListingsOverview: {
        name: 'Listing Overview',
        tab: NavTabsEnum.Listings,
        icon: navIcons.Listings,
        to: 'campaigns',
      },
      ListingReports: {
        name: 'Listing Reports',
        tab: NavTabsEnum.ListingReports,
        icon: navIcons.listingReports,
        to: 'listings/reports',
      },
    },
  },
  // Savings: {
  //   name: 'Savings',
  //   tab: NavTabsEnum.SAVINGS,
  //   icon: navIcons.Management,
  //   inner: {
  //     SavingsOverview: {
  //       name: 'Overview',
  //       tab: NavTabsEnum.SAVINGS,
  //       icon: navIcons.SavingsOverview,
  //       to: 'savings',
  //     },
  //     SavingsCompanies: {
  //       name: 'Organizations',
  //       to: 'savings/companies',
  //       tab: NavTabsEnum.SAVINGS,
  //       icon: <BagIcon />,
  //     },
  //     savingsProducts: {
  //       name: 'Products',
  //       to: 'savings/products',
  //       icon: <SavingsProductIcon />,
  //     },
  //   },
  // },
  Topics: {
    name: 'Topics',
    tab: NavTabsEnum.Topics,
    icon: navIcons.Topics,
    to: 'interest',
  },
  Support: {
    name: 'Support',
    icon: navIcons.Support,
    tab: NavTabsEnum.Support,
    inner: {
      Faq: {
        name: 'FAQ',
        tab: NavTabsEnum.FAQ,
        icon: navIcons.Faq,
        to: 'support/Faq',
      },
    },
  },
  Report: {
    name: 'Report',
    tab: NavTabsEnum.Report,
    icon: navIcons.Report,
    to: 'report',
  },
  Management: {
    name: 'Management',
    icon: navIcons.Management,
    tab: NavTabsEnum.Management,
    inner: {
      Updates: {
        name: 'Updates',
        tab: NavTabsEnum.Updates,
        icon: navIcons.Updates,
        to: 'explore',
      },
      News: {
        name: 'News',
        tab: NavTabsEnum.News,
        icon: navIcons.News,
        to: 'news',
      },
    },
  },
  Groups: {
    name: 'Groups',
    icon: navIcons.Groups,
    tab: NavTabsEnum.Groups,
    to: 'groups',
  },
  Plans: {
    name: 'Plans',
    tab: NavTabsEnum.Plans,
    icon: navIcons.Plans,
    to: 'plans',
  },
  Notification: {
    name: 'Notification',
    icon: navIcons.Notification,
    tab: NavTabsEnum.Notification,
    inner: {
      notificationSchedule: {
        name: 'Notification Schedule',
        to: 'notifications',
        tab: NavTabsEnum.NotificationSchedule,
      },
      notificationClass: {
        name: 'Notification Class',
        to: 'notifications/class',
        tab: NavTabsEnum.NotificationClass,
      },
    },
  },
  Wallet: {
    name: 'Wallet',
    icon: navIcons.Wallet,
    tab: 'WALLET',
    inner: {
      walletSettings: {
        name: 'Wallet Settings',
        tab: NavTabsEnum.Wallet,
        icon: navIcons.Wallet,
        to: 'wallet',
      },
      swapSettings: {
        name: 'Swap Settings',
        tab: NavTabsEnum.Swap,
        icon: navIcons.Wallet,
        to: 'fx-exchange-rate',
      },
    },
  },
  Settings: {
    name: 'Settings',
    icon: navIcons.Settings,
    tab: NavTabsEnum.Settings,
    inner: {
      accessCode: {
        name: 'Access Code',
        icon: navIcons.accessCode,
        tab: NavTabsEnum.AccessCode,
        to: 'access-code',
      },
      // PAGE: ON HOLD FOR NOW
      // referralProgram: {
      //   name: 'Referral Program',
      //   icon: navIcons.referralProgram,
      //   tab: NavTabsEnum.ReferralProgram,
      //   to: 'referral-program',
      // },
      usersReferral: {
        name: 'Users Referrals',
        icon: navIcons.usersReferral,
        to: 'user-referral',
        tab: NavTabsEnum.UsersReferrals,
      },
      depositSettings: {
        name: 'Deposit Settings',
        icon: navIcons.depositSettings,
        tab: NavTabsEnum.DepositSettings,
        to: 'deposit-settings',
      },
      rewardScheme: {
        name: 'Reward Scheme',
        icon: navIcons.Report,
        tab: NavTabsEnum.RewardScheme,
        to: 'reward-schemes',
      },
      rewardBanks: {
        name: 'Reward Banks',
        icon: navIcons.Report,
        tab: NavTabsEnum.RewardBanks,
        to: 'reward-banks',
      },
      platformSettings: {
        name: 'Platform Settings',
        icon: navIcons.platformSettings,
        tab: NavTabsEnum.PlatformSettings,
        to: 'app-version-setting',
      },
      bankWireDetails: {
        name: 'Bank Wire Details',
        icon: navIcons.bankWireDetails,
        tab: NavTabsEnum.BankWireDetails,
        to: 'bank-details',
      },
      cryptoDetails: {
        name: 'Crypto Details',
        icon: navIcons.cryptoDetails,
        tab: NavTabsEnum.CryptoDetails,
        to: 'crypto-setting',
      },
      subscription: {
        name: 'Subscription',
        icon: navIcons.subscription,
        tab: NavTabsEnum.Subscription,
        to: 'plans/subscription-setting',
      },
    },
  },
};

export const ADMIN_TAB_FIELDS = {
  OVERVIEW: 'Overview',
  ANNOUNCEMENTS: 'Announcements',
  USERS: 'Users',
  USERS_NPS: 'User NPS',
  STARTUPS: 'Startups',
  KEY_STATISTICS: 'Key Statistics',
  STOCK_LISTINGS: 'Stock Listings',
  BUY_SELL_ORDER: 'Buy/Sell Order',
  LISTINGS: 'Listings',
  TOPICS: 'Topics',
  FAQ: 'FAQ',
  REPORT: 'Report',
  NEWS: 'News',
  GROUPS: 'Groups',
  PLANS: 'Plans',
  NOTIFICATION_SCHEDULE: 'Notification Schedule',
  WALLET_SETTINGS: 'Wallet Settings',
  ACCESS_CODE: 'Access Code',
  REFERRAL_PROGRAM: 'Referral Program',
  USERS_REFERRALS: 'User Referral',
  DEPOSIT_SETTINGS: 'Deposit Settings',
  PLATFORM_SETTINGS: 'Platform Settings',
  BANK_WIRE_DETAILS: 'Bank Wire Details',
  CRYPTO_DETAILS: 'Crypto Details',
  SUBSCRIPTION: 'Subscription',
  DIVIDEND: 'Dividend',
  NOTIFICATION_CLASS: 'Notification Class',
  INVESTOR_UPDATES: 'Investor Updates',
  REWARD_SCHEME: 'Reward Scheme',
  REWARD_BANKS: 'Reward Banks',
  SWAP_SETTINGS: 'Swap Settings',
};

export const extractNamedTabFields = tabs => {
  const namedTabs = [];
  Object.keys(tabs).forEach(key => {
    const tab = tabs[key];
    if (tab.inner) {
      // If tab has inner objects, recursively extract from inner tabs
      const innerNamedTabs = extractNamedTabFields(tab.inner);
      namedTabs.push(...innerNamedTabs);
    } else if (tab.name && (tab.to || tab.to === '')) {
      // If tab has both name and to fields, add its name to the array
      namedTabs.push(tab.name);
    }
  });
  return namedTabs;
};

export const monthsOfTheYear = [
  {key: 'JAN', value: 'January'},
  {key: 'FEB', value: 'February'},
  {key: 'MAR', value: 'March'},
  {key: 'APR', value: 'April'},
  {key: 'MAY', value: 'May'},
  {key: 'JUN', value: 'June'},
  {key: 'JUL', value: 'July'},
  {key: 'AUG', value: 'August'},
  {key: 'SEP', value: 'September'},
  {key: 'OCT', value: 'October'},
  {key: 'NOV', value: 'November'},
  {key: 'DEC', value: 'December'},
];

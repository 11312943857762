import {DatePicker, Form, Input, Select, Spin} from 'antd';
import styles from './settleOrder.module.scss';
import {stockOrderStatus, walletCurrencies} from 'utils/mock';
import backIcon from 'images/back.png';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_ALL_BROKERS,
  GET_ALL_STOCKS,
  GET_STOCKS_ORDER_BY_ID,
} from 'graphql/queries/stocks';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {SETTLE_ORDER} from 'graphql/mutations/stocks';
import {toast} from 'react-toastify';
import {Button} from '@dabafinance/react-components';
import {OrderNotes} from '../ViewOrders/HandleOrders';
import moment from 'moment';
import {computeFeeFromFeeSchedule} from 'utils/helper';

const SettleOrder = () => {
  const [noteModal, setNoteModal] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {id} = useParams();

  const {data, loading, refetch} = useQuery(GET_STOCKS_ORDER_BY_ID, {
    variables: {
      orderId: id,
    },
    fetchPolicy: 'no-cache',
  });

  const [settleOrder, {loading: loadingUpdate}] = useMutation(SETTLE_ORDER);
  const {data: getAllBrokers, loading: loadingAllBrokers} =
    useQuery(GET_ALL_BROKERS);

  useEffect(() => {
    if (data?.getOrderById?.data) {
      const {
        direction,
        fillQuantity,
        type,
        feeSchedule,
        quantity,
        totalFee,
        limitPrice,
        staticFee,
        broker,
        price,
        company,
        assetCurrency,
      } = data?.getOrderById?.data;
      form.setFieldsValue({
        direction,
        fillQuantity: quantity - fillQuantity,
        assetCurrency,
        totalFee,
        broker: broker?.id,
        floatingFee: computeFeeFromFeeSchedule(feeSchedule, price, {
          skipFixed: true,
          skipStatic: true,
          skipVariable: true,
        }),
        staticFee,
        type,
        price: limitPrice,
        ticker: company.ticker,
      });
    }
  }, [data?.getOrderById]);

  const handleSettleOrder = values => {
    const {
      status,
      direction,
      fillQuantity,
      type,
      price,
      ticker,
      fillDate,
      company,
      assetCurrency,
      broker,
      brokerOrderId,
    } = values;
    settleOrder({
      variables: {
        input: {
          status,
          orderId: id,
          lastFillDate: fillDate ? moment(fillDate).format() : null,
          direction,
          fillQuantity: Number(fillQuantity),
          type,
          ...(broker && {broker}),
          ...(brokerOrderId && {brokerOrderId}),
          assetPrice: Number(price),
          ticker,
          company,
          currency: assetCurrency,
        },
      },
      refetchQueries: [
        {
          query: GET_ALL_STOCKS,
          variables: {
            page: 1,
            'paginate': true,
            'limit': 10,
            'newFirst': true,
            openOrders: true,
            includeOrdersWithElapsedExpiry: true,
          },
        },
      ],
    })
      .then(({data: settleOrder}) => {
        const {statusCode, __typename, message} = settleOrder.settleOrder;
        if (__typename === 'Error' || statusCode === 400) {
          toast.error(`Something went wrong!!! - ${message} `);
        } else {
          toast.success('Filled Successfully');
          navigate('/dashboard/stocks/listings?route=order');
        }
      })
      .catch(err => toast.error(`Something went wrong!! - ${err}`));
  };

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading Stock Order..</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1>
          {['OPEN', 'PARTIALLY_FILLED'].includes(
            data?.getOrderById?.data?.status,
          )
            ? 'Fill'
            : ''}{' '}
          Stock Order
        </h1>
      </div>
      <div className={styles.topButton}>
        <Button onClick={() => setNoteModal(true)} label="View / Edit Notes" />
      </div>
      <Form
        onFinish={handleSettleOrder}
        form={form}
        className={styles.form}
        layout="vertical">
        <Form.Item
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This is a required field'}]}
          name="ticker"
          label="Ticker">
          <Input disabled />
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This is a required field'}]}
          name="assetCurrency"
          label="Currency">
          <Select disabled>
            {walletCurrencies.map(data => (
              <Select.Option key={data.key}>{data.key}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This is a required field'}]}
          name="direction"
          label="Direction">
          <Select disabled>
            <Select.Option key="BUY">Buy</Select.Option>
            <Select.Option key="SELL">Sell</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This is a required field'}]}
          name="type"
          label="Order Type">
          <Select disabled>
            <Select.Option key="LIMIT">Limit</Select.Option>
            <Select.Option key="MARKET">Market</Select.Option>
          </Select>
        </Form.Item>
        <div className={styles.fees}>
          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a required field'}]}
            name="staticFee"
            label="Static Fee">
            <Input disabled />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a required field'}]}
            name="floatingFee"
            label="Floating Fee">
            <Input disabled />
          </Form.Item>
          <Form.Item
            className={styles.smallWidth}
            rules={[{required: true, message: 'This is a required field'}]}
            name="totalFee"
            label="Total Fee ">
            <Input disabled />
          </Form.Item>
        </div>
        <Form.Item
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This is a required field'}]}
          name="price"
          label={
            <div>
              Order Asset Price (Current Asset Price:{' '}
              <span style={{color: 'green', fontWeight: 'bold'}}>
                {data?.getOrderById?.data?.assetCurrency}{' '}
                {data?.getOrderById?.data?.company?.stock?.lastPrice}
              </span>
              )
            </div>
          }>
          <Input type="number" />
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This is a required field'}]}
          name="fillQuantity"
          label={
            <div>
              Fill Quantity Left (Total Quantity:{' '}
              <span style={{color: 'green', fontWeight: 'bold'}}>
                {data?.getOrderById?.data?.quantity}
              </span>
              )
            </div>
          }>
          <Input type="number" />
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          rules={[{required: true, message: 'This is a required field'}]}
          name="status"
          label="Order Status">
          <Select>
            {stockOrderStatus
              .filter(
                data =>
                  data.key === 'FILLED' || data.key === 'PARTIALLY_FILLED',
              )
              ?.map(data => (
                <Select.Option key={data.key}>{data.name}</Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className={styles.fieldWidth}
          name="broker"
          label="Select Broker">
          <Select
            className={styles.fieldWidth}
            allowClear
            showSearch
            loading={loadingAllBrokers}>
            {getAllBrokers?.getBrokers?.data?.map(data => (
              <Select.Option key={data?.id}>
                {data?.name} - ({data?.assetClass})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className={styles.fieldWidth}
          name="brokerOrderId"
          label="Broker Order ID">
          <Input />
        </Form.Item>
        <Form.Item
          initialValue={
            data?.getOrderById?.data?.lastFillDate
              ? moment(
                  data?.getOrderById?.data?.lastFillDate,
                  'YYYY-MM-DD HH:mm:ss',
                )
              : ''
          }
          name="fillDate"
          label="Filled Date">
          <DatePicker
            defaultValue={
              data?.getOrderById?.data?.lastFillDate
                ? moment(
                    data?.getOrderById?.data?.lastFillDate,
                    'YYYY-MM-DD HH:mm:ss',
                  )
                : ''
            }
            showTime={{
              defaultValue: moment('00:00', 'HH:mm:ss'),
            }}
            format={'YYYY-MM-DD HH:mm:ss'}
            style={{width: 340}}
          />
        </Form.Item>
        {!['FILLED', 'SETTLED'].includes(data?.getOrderById?.data?.status) && (
          <SubmitButton disabled={loadingUpdate} label="Fill Order" />
        )}
      </Form>
      <OrderNotes
        orderModal={noteModal}
        setOrderModal={setNoteModal}
        notes={{
          id: data?.getOrderById?.data?.id,
          notes: data?.getOrderById?.data?.notes,
        }}
        refetch={refetch}
      />
    </div>
  );
};

export default SettleOrder;

import {Upload as ImageUpload} from 'antd';
import {useState} from 'react';
import styles from './companyImage.module.scss';
import imgUpload from 'images/upload_image.png';
import {Button} from '@dabafinance/react-components';
import '../companyVideo/player.css';
import {S3Client} from '@aws-sdk/client-s3';
import {Upload} from '@aws-sdk/lib-storage';
import {toast} from 'react-toastify';
import {resizeImageFile} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';

const UploadImage = ({
  bucket,
  large,
  label = 'Upload Image',
  setPreviewUrl,
  previewUrl,
  setImgUrl,
  thumbnail,
  icon,
  /**
   * Limits acceptable file types a user can upload. Defaults to image type.
   */
  accept = 'image/*',
}) => {
  const UploadToBucket = async file => {
    const payload = await resizeImageFile(file);
    const fileName = `daba-finance-invest-africa-${Date.now()}-${payload?.name.replace(
      / /g,
      '',
    )}`;
    const target = {
      Bucket: bucket,
      Key: fileName,
      Body: payload,
      ContentType: payload.type,
    };
    const cred = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    };
    let imageUrl = '';
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({
          region: 'us-east-1',
          credentials: cred,
        }),
        leavePartsOnError: false,
        params: target,
      });
      const res = await parallelUploads3.done();

      if (res.$metadata.httpStatusCode === 200) {
        imageUrl = `https://${bucket}.s3.amazonaws.com/${fileName}`;
        setImgUrl(imageUrl);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const handleChange = async ({file}) => {
    const temp = file.originFileObj;
    const url = URL.createObjectURL(file.originFileObj);
    setPreviewUrl(url);
    await UploadToBucket(temp);
  };
  const uploadButton = (
    <div
      className={
        large
          ? styles['large-company-upload']
          : thumbnail
          ? styles['thumbail-image']
          : icon
          ? styles['icon-image']
          : styles['company-upload']
      }>
      <img className={styles['company-image']} src={imgUpload} alt="upload" />
      <div className={styles['upload-text']}>
        <h3>{label}</h3>
      </div>
    </div>
  );

  return (
    <div>
      {previewUrl ? (
        <div
          className={
            large
              ? styles['large-image-uploaded']
              : thumbnail
              ? styles['thumbail-upload']
              : icon
              ? styles['icon-upload']
              : styles['image-uploaded']
          }>
          <img src={previewUrl} alt="company" />
          <Button
            label="Delete Image"
            type="secondary"
            className={styles['button-red']}
            onClick={() => {
              setImgUrl(null);
              setPreviewUrl(null);
            }}
          />
        </div>
      ) : (
        <ImageUpload
          name="image"
          listType="picture"
          onChange={handleChange}
          maxCount={1}
          accept={accept}>
          {uploadButton}
        </ImageUpload>
      )}
    </div>
  );
};

export const CampaignImage = ({bucket, large, setImgUrl}) => {
  const [previewUrl, setPreviewUrl] = useState('');

  const UploadToBucket = async payload => {
    const fileName = `${Date.now()}-${payload?.name.replace(/ /g, '')}`;
    const target = {
      Bucket: bucket,
      Key: fileName,
      Body: payload,
    };
    const cred = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_SECRET_KEY,
    };
    let imageUrl = '';
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({
          region: 'us-east-1',
          credentials: cred,
        }),
        leavePartsOnError: false,
        params: target,
      });
      const res = await parallelUploads3.done();

      if (res.$metadata.httpStatusCode === 200) {
        imageUrl = `https://${bucket}.s3.amazonaws.com/${fileName}`;
        setImgUrl(imageUrl);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const handleChange = async ({file}) => {
    const temp = file.originFileObj;
    const url = URL.createObjectURL(file.originFileObj);
    setPreviewUrl(url);
    await UploadToBucket(temp);
  };
  const uploadButton = (
    <div
      className={
        large ? styles['large-company-upload'] : styles['c-company-upload']
      }>
      <img className={styles['company-image']} src={imgUpload} alt="upload" />
      <div style={{marginTop: 6}}>Upload PDF</div>
    </div>
  );

  return (
    <div>
      {previewUrl ? (
        <div>
          <div className={styles['top-buttons']}>
            <Button
              label="Upload New PDF"
              type="secondary"
              className={styles.button}
            />
            <Button
              label="Delete Report"
              type="secondary"
              className={styles['delete-button']}
              onClick={() => {
                setPreviewUrl('');
              }}
            />
          </div>
          <img src={previewUrl} alt="company" />
        </div>
      ) : (
        <ImageUpload
          name="image"
          listType="picture"
          onChange={handleChange}
          maxCount={1}>
          {uploadButton}
        </ImageUpload>
      )}
    </div>
  );
};

export default UploadImage;

import {useEffect, useState} from 'react';
import styles from './mobileSetting.module.scss';
import {Form, Input, Select, Spin, Switch} from 'antd';
import {useMutation, useQuery} from '@apollo/client';
import {
  GET_APP_CONFIGURATION,
  UPDATE_APP_CONFIGURATION,
} from 'graphql/queries/mobileSettings';
import SubmitButton from 'components/Startup/components/SubmitButton';
import DBModal from 'components/modal/modal';
import appleLogo from 'images/ios.png';
import googleLogo from 'images/googleplay.png';
import {toast} from 'react-toastify';
import {Button} from '@dabafinance/react-components';
import ReviewSettings from './ReviewSettings';
import EmailDisclosure from './emailDisclosure/emailDisclosure';
import AdminAuthConfig from './AdminAuthConfig';
import {NavTabsEnum} from 'utils/constants';
import {makeSelectTabOperations} from 'redux/store/auth';
import {useSelector} from 'react-redux';
import DocumentsConfig from './DocumentsConfig';
import {walletCurrencies} from 'utils/mock';
import FadeEffect from 'components/animation/fadeEffect';
import deleteIcon from 'images/delete.svg';
import plusIcon from 'images/plus-circle.png';

const MobileSetting = () => {
  const [latestVersion, setLatestVersion] = useState('');
  const [critical, setCritical] = useState(false);
  const [activeTab, setActiveTab] = useState('APP');
  const [androidVersion, setAndroidVersion] = useState('');
  const [dabaWhatsappNumber, setDabaWhatsappNumber] = useState('');
  const [brvmVideoId, setBrvmVideoId] = useState('');
  const [IOSVersion, setIOSVersion] = useState('');
  const [disableFields, setDisableFields] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [openRewardModal, setOpenRewardModal] = useState(false);
  const [message, setMessage] = useState('');
  const [totalEstimatedRewardsValue, setTotalEstimatedRewardsValue] = useState([
    {
      currency: 'USD',
      amount: 0,
    },
  ]);
  const width = 730;

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.PlatformSettings),
  );

  const {loading, data, refetch} = useQuery(GET_APP_CONFIGURATION, {
    fetchPolicy: 'no-cache',
  });
  const [updateAppConfig, {loading: updateLoading}] = useMutation(
    UPDATE_APP_CONFIGURATION,
  );

  useEffect(() => {
    setLatestVersion(data?.getAppConfig?.config?.versionInfo?.latestVersion);
    setCritical(data?.getAppConfig?.config?.versionInfo?.isCritical);
    setMessage(data?.getAppConfig?.config?.versionInfo?.message);
    setIOSVersion(data?.getAppConfig?.config?.versionInfo?.ios);
    setAndroidVersion(data?.getAppConfig?.config?.versionInfo?.android);
    setDabaWhatsappNumber(data?.getAppConfig?.config?.dabaWhatsappNumber);
    setBrvmVideoId(data?.getAppConfig?.config?.brvmVideoId);
    setTotalEstimatedRewardsValue(
      data?.getAppConfig?.config?.totalEstimatedRewardsValue,
    );
  }, [data?.getAppConfig]);

  const handleAddRewardSettings = () => {
    setTotalEstimatedRewardsValue([
      ...totalEstimatedRewardsValue,
      {cash: 0, currency: 'USD'},
    ]);
  };
  const onRewardSettingsDelete = id => {
    const filteredFormValue = totalEstimatedRewardsValue.filter(
      (item, index) => index !== id,
    );
    setTotalEstimatedRewardsValue(filteredFormValue);
  };
  const handleChangeRewardSettingsValue = (i, name, e) => {
    const newFormValues = [...totalEstimatedRewardsValue];
    newFormValues[i][name] = e;
    setTotalEstimatedRewardsValue(newFormValues);
  };

  const handleSubmit = () => {
    updateAppConfig({
      variables: {
        data: {
          versionInfo: {
            latestVersion: latestVersion.trim(),
            message: message.trim(),
            android: androidVersion.trim(),
            ios: IOSVersion.trim(),
            isCritical: critical,
          },
          dabaWhatsappNumber,
          brvmVideoId,
          totalEstimatedRewardsValue: totalEstimatedRewardsValue.map(item => ({
            amount: Number(item.amount),
            currency: item.currency,
          })),
        },
      },
      refetchQueries: [{query: GET_APP_CONFIGURATION}],
    })
      .then(({data: {updateAppConfig}}) => {
        if (updateAppConfig.__typename === 'Error') {
          toast.error(updateAppConfig.message);
        } else {
          toast.success('Updated Successfully');
          setOpenModal(false);
          setOpenRewardModal(false);
        }
      })
      .catch(error => toast.error(error));
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <Spin />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      <div className={styles['nav-header']}>
        <h1>Platform Configuration Settings</h1>
      </div>
      <div className={styles['details-toggler']}>
        <span
          className={activeTab === 'APP' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('APP')}>
          {' '}
          App Settings
        </span>
        <span
          className={activeTab === 'DOCUMENT' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('DOCUMENT')}>
          {' '}
          Documents Setting
        </span>
        <span
          className={activeTab === 'REVIEW' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('REVIEW')}>
          {' '}
          Review Settings
        </span>
        <span
          className={
            activeTab === 'EMAIL_DISCLOSURE' ? styles['active-toggle'] : ''
          }
          onClick={() => setActiveTab('EMAIL_DISCLOSURE')}>
          {' '}
          Email Disclosures
        </span>
        <span
          className={activeTab === 'ADMIN_AUTH' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('ADMIN_AUTH')}>
          Admin Auth
        </span>
        <span
          className={
            activeTab === 'REWARD_SETTINGS' ? styles['active-toggle'] : ''
          }
          onClick={() => setActiveTab('REWARD_SETTINGS')}>
          Reward Settings
        </span>

        {/* @ on-Hold for now */}
        {/* <span
          className={activeTab === 'SIGN-OUT' ? styles['active-toggle'] : ''}
          onClick={() => setActiveTab('SIGN-OUT')}>
          {' '}
          Force Users Sign out
        </span> */}
      </div>
      {activeTab === 'APP' && (
        <div className={styles.body}>
          <div className={styles.header}>
            <div className={styles.links}>
              <div className={styles.icons}>
                <img src={appleLogo} alt="" />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://apps.apple.com/us/app/daba-finance/id1596446926">
                  Link App Store
                </a>
              </div>
              <div className={styles.icons}>
                <img src={googleLogo} alt="" />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://play.google.com/store/apps/details?id=com.joindaba.daba">
                  Link Play Store
                </a>
              </div>
            </div>
            <SubmitButton
              label={'Edit Settings'}
              disabled={!tabOperations?.update}
              handleClick={() => setDisableFields(toggle => !toggle)}
            />
          </div>
          <Form
            onFinish={() => setOpenModal(true)}
            className={styles.form}
            layout="vertical">
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              style={{width}}
              name="version"
              initialValue={
                data?.getAppConfig?.config?.versionInfo?.latestVersion
              }
              label="Latest Version">
              <Input
                defaultValue={
                  data?.getAppConfig?.config?.versionInfo?.latestVersion
                }
                value={latestVersion}
                disabled={disableFields}
                onChange={e => setLatestVersion(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              style={{width}}
              name="androidVersion"
              initialValue={data?.getAppConfig?.config?.versionInfo?.android}
              label="Android Version">
              <Input
                defaultValue={data?.getAppConfig?.config?.versionInfo?.android}
                value={androidVersion}
                disabled={disableFields}
                onChange={e => setAndroidVersion(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              style={{width}}
              name="iosVersion"
              initialValue={data?.getAppConfig?.config?.versionInfo?.ios}
              label="IOS Version">
              <Input
                defaultValue={data?.getAppConfig?.config?.versionInfo?.ios}
                value={IOSVersion}
                disabled={disableFields}
                onChange={e => setIOSVersion(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              style={{width}}
              name="dabaWhatsappNumber"
              initialValue={data?.getAppConfig?.config?.dabaWhatsappNumber}
              label="Daba Whatsapp Number">
              <Input
                defaultValue={data?.getAppConfig?.config?.dabaWhatsappNumber}
                value={dabaWhatsappNumber}
                disabled={disableFields}
                onChange={e => setDabaWhatsappNumber(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              style={{width}}
              name="brvmVideoId"
              initialValue={data?.getAppConfig?.config?.brvmVideoId}
              label="Brvm Video ID">
              <Input
                defaultValue={data?.getAppConfig?.config?.brvmVideoId}
                value={brvmVideoId}
                disabled={disableFields}
                onChange={e => setBrvmVideoId(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              name="critical"
              label="Is Update Critical?"
              initialValue={
                !!data?.getAppConfig?.config?.versionInfo?.isCritical
              }>
              <Switch
                defaultChecked={
                  data?.getAppConfig?.config?.versionInfo?.isCritical
                }
                checked={critical}
                disabled={disableFields}
                onChange={e => setCritical(e)}
              />
            </Form.Item>
            <Form.Item
              rules={[{required: true, message: 'Required Field'}]}
              style={{width}}
              name="message"
              initialValue={data?.getAppConfig?.config?.versionInfo?.message}
              label="Message">
              <Input.TextArea
                value={message}
                disabled={disableFields}
                defaultValue={data?.getAppConfig?.config?.versionInfo?.message}
                onChange={e => setMessage(e.target.value)}
                rows={4}
              />
            </Form.Item>
            {!disableFields && (
              <SubmitButton label="Update" disabled={!tabOperations?.update} />
            )}
          </Form>
        </div>
      )}
      {activeTab === 'REVIEW' && (
        <ReviewSettings
          data={data?.getAppConfig?.config?.platformReviewSettings}
        />
      )}
      {activeTab === 'EMAIL_DISCLOSURE' && <EmailDisclosure />}
      {activeTab === 'DOCUMENT' && <DocumentsConfig />}

      {activeTab === 'ADMIN_AUTH' && (
        <AdminAuthConfig
          enabled={data?.getAppConfig?.config?.securitySettings?.admin2FA}
          refetch={refetch}
        />
      )}
      {activeTab === 'REWARD_SETTINGS' && (
        <div className={styles.body}>
          <Form
            onFinish={() => setOpenRewardModal(true)}
            className={styles.form}
            layout="vertical">
            {totalEstimatedRewardsValue.map((item, index) => (
              <FadeEffect key={index}>
                <div className={styles['key-facts']} key={index}>
                  <div>
                    <Form.Item
                      rules={[{required: true, message: 'Required Field'}]}
                      style={{width}}
                      initialValue={item.amount}
                      label="Total Estimated Reward Amount">
                      <Input
                        type="number"
                        value={item.amount}
                        defaultValue={item.amount}
                        onChange={e =>
                          handleChangeRewardSettingsValue(
                            index,
                            'amount',
                            e.target.value,
                          )
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      rules={[{required: true, message: 'Required Field'}]}
                      style={{width}}
                      initialValue={item.currency}
                      label="Currency">
                      <Select
                        name="currency"
                        placeholder="USD"
                        value={item.currency}
                        defaultValue={item.currency}
                        onChange={e =>
                          handleChangeRewardSettingsValue(index, 'currency', e)
                        }>
                        {walletCurrencies.map(({key, name}) => (
                          <Select.Option key={key}>{name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {(!item.amount || !item.currency) && (
                      <span className={styles['warning']}>
                        Please note both fields are required
                      </span>
                    )}
                  </div>
                  <img
                    onClick={() => onRewardSettingsDelete(index)}
                    src={deleteIcon}
                    alt=""
                  />
                </div>
              </FadeEffect>
            ))}
            <div
              onClick={handleAddRewardSettings}
              className={styles['add-button']}>
              <img src={plusIcon} alt="" />
              <h1>Add New Settings</h1>
            </div>
            <SubmitButton label="Update" disabled={!tabOperations?.update} />
          </Form>
        </div>
      )}

      {/* @ on-Hold for now */}
      {/* {activeTab === 'SIGN-OUT' && <ForceSignOut />} */}
      <div>
        <DBModal
          isOpen={openModal}
          handleClose={() => setOpenModal(false)}
          content={
            <div className={styles['modal-container']}>
              <h1>Are you sure?</h1>
              <span>
                This will{' '}
                <span className={styles['m-warning']}>
                  {critical ? 'Force' : 'Notify'}
                </span>{' '}
                users with app version less than{' '}
                <span className={styles['m-version']}>{latestVersion}</span>to
                update the app
              </span>
              <div>
                <Button
                  label="Cancel"
                  className={styles.button}
                  type="secondary"
                  onClick={() => setOpenModal(false)}
                />
                <Button
                  label="Proceed"
                  className={styles.button}
                  onClick={handleSubmit}
                  disabled={updateLoading}
                />
              </div>
            </div>
          }
        />
      </div>

      {/* reward settings modal */}
      <div>
        <DBModal
          isOpen={openRewardModal}
          handleClose={() => setOpenRewardModal(false)}
          content={
            <div className={styles['modal-container']}>
              <h1>Are you sure?</h1>
              <span>
                This will{' '}
                <span className={styles['m-warning']}>
                  update the total estimated reward amount
                </span>{' '}
                in the app?
              </span>
              <div>
                <Button
                  label="Cancel"
                  className={styles.button}
                  type="secondary"
                  onClick={() => setOpenRewardModal(false)}
                />
                <Button
                  label="Proceed"
                  className={styles.button}
                  onClick={handleSubmit}
                  disabled={updateLoading}
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
};

export default MobileSetting;

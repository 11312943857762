import {DatePicker, Form, Select, Switch} from 'antd';
import styles from './reports.module.scss';
import {useState} from 'react';
import {
  paymentProviders,
  transactionTypeEnum,
  walletCurrencies,
} from 'utils/mock';
import {useLazyQuery} from '@apollo/client';
import {
  GENERATE_CUSTOMER_ACTIVITY,
  GENERATE_RECONCILIATION_REPORT,
  GENERATE_TRANSACTION_REPORT,
} from 'graphql/queries/wallet';
import moment from 'moment';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {ConfirmModal} from 'pages/adminOpportunities/stocksListings/ViewOrders/HandleOrders';

const ReportFilters = ({reportType, setOpenModal}) => {
  const [timeRange, setTimeRange] = useState(true);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [generateCustomerActivityReport, {loading: customerActivity}] =
    useLazyQuery(GENERATE_CUSTOMER_ACTIVITY);
  const [generateReconciliationReport, {loading: reconciliation}] =
    useLazyQuery(GENERATE_RECONCILIATION_REPORT);
  const [generateTransactionReport, {loading: transaction}] = useLazyQuery(
    GENERATE_TRANSACTION_REPORT,
  );

  const [form] = Form.useForm();

  const handleConfirm = () => {
    setOpenConfirmModal(true);
  };

  const handleSubmit = () => {
    const values = form.getFieldsValue();
    if (reportType === 'RECONCILIATION') {
      generateReconciliationReport({
        variables: {
          filters: {
            currency: values?.selectCurrency,
            date: {
              endDate: values?.endDate
                ? moment(values?.endDate).format()
                : null,
              range: values?.range || null,
              startDate: values?.startDate
                ? moment(values?.startDate).format()
                : null,
            },
            paymentProvider: values?.provider,
            status: values?.status,
          },
        },
      })
        .then(({data: {generateReconciliationReport}}) => {
          if (generateReconciliationReport?.__typname === 'ERROR') {
            toast.error(generateReconciliationReport?.message);
          }
          toast.success(generateReconciliationReport?.responseMessage);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          form.resetFields();
          setOpenConfirmModal(false);
          setOpenModal(false);
        });
    } else if (reportType === 'CUSTOMER_ACTIVITY') {
      generateCustomerActivityReport({
        variables: {
          filters: {
            currency: values?.selectCurrency,
            date: {
              endDate: values?.endDate
                ? moment(values?.endDate).format()
                : null,
              range: values?.range || null,
              startDate: values?.startDate
                ? moment(values?.startDate).format()
                : null,
            },
            paymentProvider: values?.provider,
            status: values?.status,
          },
        },
      })
        .then(({data: {generateCustomerActivityReport}}) => {
          if (generateCustomerActivityReport?.__typname === 'ERROR') {
            toast.error(generateCustomerActivityReport?.message);
          }
          toast.success(generateCustomerActivityReport?.responseMessage);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          form.resetFields();
          setOpenConfirmModal(false);
          setOpenModal(false);
        });
    } else if (reportType === 'TRANSACTION') {
      generateTransactionReport({
        variables: {
          filters: {
            currency: values?.selectCurrency,
            type: values?.type,
            direction: values?.direction,
            date: {
              endDate: values?.endDate
                ? moment(values?.endDate).format()
                : null,
              range: values?.range || null,
              startDate: values?.startDate
                ? moment(values?.startDate).format()
                : null,
            },
            paymentProvider: values?.provider,
            status: values?.status,
          },
        },
      })
        .then(({data: {generateTransactionReport}}) => {
          if (generateTransactionReport?.__typname === 'ERROR') {
            toast.error(generateTransactionReport?.message);
          }
          toast.success(generateTransactionReport?.responseMessage);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          form.resetFields();
          setOpenConfirmModal(false);
          setOpenModal(false);
        });
    }
  };

  return (
    <div className={styles.root}>
      <h1>Report Filter</h1>
      <span className={styles.subtext}>
        N.B:{' '}
        {reportType === 'RECONCILIATION'
          ? 'You can submit without adding any filters, the filters are optional'
          : 'Please provide either a start date or range before submitting'}
      </span>
      <Form
        form={form}
        className={styles.form}
        onFinish={handleConfirm}
        layout="vertical">
        <Form.Item name="selectCurrency" label="Select Currency">
          <Select>
            {walletCurrencies.map(data => (
              <Select.Option key={data.key}>{data.key}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="provider" label="Select Provider">
          <Select>
            {paymentProviders.map(data => (
              <Select.Option key={data.key}>{data.key}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="status" label="Status">
          <Select>
            <Select.Option key="PENDING">PENDING</Select.Option>
            <Select.Option key="SUCCESSFUL">SUCCESSFUL</Select.Option>
            <Select.Option key="FAILED">FAILED</Select.Option>
          </Select>
        </Form.Item>
        <Switch
          checkedChildren="Time Range"
          unCheckedChildren="Date Range"
          checked={timeRange}
          onChange={e => setTimeRange(e)}
          defaultChecked
          size="large"
          className={styles.switch}
        />
        {reportType === 'TRANSACTION' && (
          <div>
            <Form.Item name="direction" label="Direction">
              <Select>
                {transactionTypeEnum.map((data, index) => (
                  <Select.Option key={index} value={data.key}>
                    {data.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="type" label="Type">
              <Select>
                {transactionTypeEnum.map((data, index) => (
                  <Select.Option key={index} value={data.key}>
                    {data.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}
        {timeRange ? (
          <Form.Item name="range" label="Time Range">
            <Select>
              <Select.Option key="DAILY">DAILY</Select.Option>
              <Select.Option key="WEEKLY">WEEKLY</Select.Option>
              <Select.Option key="MONTHLY">MONTHLY</Select.Option>
            </Select>
          </Form.Item>
        ) : (
          <div className={styles.dates}>
            <Form.Item label="Start Date" name="startDate">
              <DatePicker format={'YYYY-MM-DD'} />
            </Form.Item>
            <Form.Item label="End Date" name="endDate">
              <DatePicker format={'YYYY-MM-DD'} />
            </Form.Item>
          </div>
        )}
        <SubmitButton
          label={'Generate Report'}
          disabled={transaction || reconciliation || customerActivity}
        />
      </Form>

      <ConfirmModal
        title={'Confirm Report Generation'}
        openModal={openConfirmModal}
        setOpenModal={setOpenConfirmModal}
        confirmText={'Are you sure you want to GENERATE this report?'}
        loading={transaction || reconciliation || customerActivity}
        handleOperation={handleSubmit}
      />
    </div>
  );
};

export default ReportFilters;

import {Spin, Table, Tag} from 'antd';
import {useState} from 'react';
import styles from './allRewardSchemes.module.scss';
import {useQuery} from '@apollo/client';
import {toast} from 'react-toastify';
import {useNavigate} from 'react-router-dom';
import {GET_ALL_REWARD_SCHEMES} from 'graphql/queries/rewardScheme';
import {NoUpdate} from 'components/noUpdate';

const ExpiredRewardSchemes = () => {
  const navigate = useNavigate();
  const [allRewardSchemes, setAllRewardSchemes] = useState([]);

  const {loading} = useQuery(GET_ALL_REWARD_SCHEMES, {
    onCompleted: data =>
      setAllRewardSchemes(data?.getAllRewardSchemes?.data?.rewardSchemes),
    errorPolicy: 'all',
    onError: error => toast.error(error?.message),
  });

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <div>
          <h4>{record?.title}</h4>
        </div>
      ),
    },
    {
      title: 'Max Reward',
      dataIndex: 'maxNumberOfRewards',
      key: 'maxNumberOfRewards',
      render: (text, record) => (
        <div>
          <h4>{record?.maxNumberOfRewards}</h4>
        </div>
      ),
    },
    {
      title: 'Waiver',
      dataIndex: 'transactionFeeWaiver',
      key: 'transactionFeeWaiver',
      render: (text, render) => (
        <div className={styles['record-label']}>
          <h4>
            {render?.rewardConfig?.transactionFeeWaiver?.percentage &&
              `${render?.rewardConfig?.transactionFeeWaiver?.percentage}%`}
          </h4>
        </div>
      ),
    },
    {
      title: 'Units',
      dataIndex: 'units',
      key: 'units',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {record?.rewardConfig?.stocks?.rewardUnits}{' '}
            {record?.rewardConfig?.stocks?.ticker}
          </h4>
        </div>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (text, record) => (
        <div className={styles['record-label']}>
          <h4>
            {record?.rewardConfig?.cash?.currency?.currency}{' '}
            {record?.rewardConfig?.cash?.amount}
          </h4>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <div className={styles['record-label']}>
          {record?.isActive ? (
            <Tag color="green">Active</Tag>
          ) : (
            <Tag color="red">Not Active</Tag>
          )}
        </div>
      ),
    },
  ];

  return (
    <div className={styles['table-container']}>
      <div className={styles.header}>
        <h1>Expired Reward Schemes</h1>
      </div>
      <div className={styles['startup-table']}>
        {loading ? (
          <div className={styles.loading}>
            <Spin size="large" />
            <h1>Loading...</h1>
          </div>
        ) : allRewardSchemes?.length >= 1 ? (
          <Table
            className={styles['user-table']}
            dataSource={allRewardSchemes.filter(
              item => item.isExpired === true,
            )}
            columns={columns}
          />
        ) : (
          <NoUpdate
            title={'No Reward Scheme'}
            description={
              'There are currently no reward scheme, you could create a new one'
            }
            buttonLabel={'Create Reward Scheme'}
            onClick={() => navigate('/dashboard/reward-schemes/new')}
          />
        )}
      </div>
    </div>
  );
};

export {ExpiredRewardSchemes};

import {useNavigate, useParams} from 'react-router-dom';
import {DatePicker, Form, Input, InputNumber, Select, Spin, Tabs} from 'antd';
import backIcon from 'images/back.png';
import styles from '../../create/createStock.module.scss';
import {useMutation, useQuery} from '@apollo/client';
import {GET_ALL_STOCKS, GET_STOCK_BY_ID} from 'graphql/queries/stocks';
import {UPDATE_STOCK} from 'graphql/mutations/stocks';
import {useEffect, useState} from 'react';
import UploadImage from 'components/Startup/profile/companyImage';
import {GET_ALL_COUNTRIES} from 'graphql/queries/users';
import SubmitButton from 'components/Startup/components/SubmitButton';
import moment from 'moment';
import {currencyCodes} from '../../create/CreateStock';
import {toast} from 'react-toastify';
import {TRANSLATE_MULTIPLE_FIELDS} from 'graphql/queries/investorUpdates';
import {requestStocksTranslation} from 'redux/store/news/actions';
import {Button} from '@dabafinance/react-components';
import {defaultLanguages} from 'pages/news/newsTypes';
import {fieldRules} from 'utils/helper';
import OrderSettings from 'pages/adminOpportunities/OrderSettings/OrderSettings';
import {useSelector} from 'react-redux';
import {makeSelectTabOperations} from 'redux/store/auth';
import {NavTabsEnum} from 'utils/constants';

const UpdateStock = () => {
  const [stockState, setStockState] = useState({
    ceo: '',
    baseCurrency: '',
    website: '',
    countries: [],
    description: '',
    marketCap: '',
    code: '',
    exchangeName: '',
    headQuarters: '',
    numberOfListings: 0,
    foundedDate: '',
    defaultClosureMessage: '',
    defaultClosureTitle: '',
  });
  const {id} = useParams();
  const [stockLogo, setStockLogo] = useState('');
  const [previewStockLogo, setPreviewStockLogo] = useState('');
  const [baseLanguage, setBaseLanguage] = useState('');
  const [translatedData, setTranslatedData] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [fees, setFees] = useState([]);
  const [form] = Form.useForm();

  const {Option} = Select;
  const navigate = useNavigate();

  const [translateText, {loading: loadingTranslation}] = useMutation(
    TRANSLATE_MULTIPLE_FIELDS,
  );

  const tabOperations = useSelector(state =>
    makeSelectTabOperations(state, NavTabsEnum.StockListings),
  );

  const handleTranslation = async () => {
    const newValues = [...translatedData];

    if (!baseLanguage && !description) {
      return toast.error(
        'Please make sure none of the necessary fields needed for translation are empty',
      );
    }

    const translateProps = {
      sourceLang: baseLanguage,
      targetLang: selectedLanguage,
      targetText1: description,
      targetText2: defaultClosureMessage,
      targetText3: defaultClosureTitle,
    };
    if (newValues.length && newValues[0]) {
      const languageExists = newValues.some(
        data => data?.language === selectedLanguage,
      );
      if (languageExists) {
        const updateTranslation = await requestStocksTranslation(
          translateText,
          translateProps,
        );
        const translationIndex = newValues.findIndex(
          data => data.language === selectedLanguage,
        );
        newValues[translationIndex].description = updateTranslation.description;
        newValues[translationIndex].defaultClosureMessage =
          updateTranslation.defaultClosureMessage;
        newValues[translationIndex].defaultClosureTitle =
          updateTranslation.defaultClosureTitle;
        newValues[translationIndex].language = updateTranslation.language;
        setTranslatedData(newValues);
      }
    } else {
      const triggerNewTranslation = await requestStocksTranslation(
        translateText,
        translateProps,
      );
      if (!triggerNewTranslation) {
        return toast.error('Something went wrong when Translating');
      }
      newValues.push(triggerNewTranslation);
      setTranslatedData(newValues);
    }
  };

  const {data, loading} = useQuery(GET_STOCK_BY_ID, {
    variables: {
      getStockExchangeByIdId: id,
    },
    fetchPolicy: 'no-cache',
  });

  const {
    ceo,
    baseCurrency,
    website,
    countries,
    description,
    marketCap,
    code,
    exchangeName,
    headQuarters,
    numberOfListings,
    foundedDate,
    defaultClosureMessage,
    defaultClosureTitle,
  } = stockState;

  const {data: getAllCountries, loading: loadingCountries} =
    useQuery(GET_ALL_COUNTRIES);

  const [updateStockExchange, {loading: loadingUpdate}] =
    useMutation(UPDATE_STOCK);

  const handleFieldChange = payload => {
    setStockState(state => ({...state, ...payload}));
  };

  const handleChangeTranslation = (key, event) => {
    let eventName = event.target.name;
    let eventValue = event.target.value;
    const newFormValues = [...translatedData];
    newFormValues[key][eventName] = eventValue;
    setTranslatedData(newFormValues);
  };

  const languageToBeTranslatedTo = defaultLanguages.filter(
    key => key.key !== baseLanguage,
  );

  useEffect(() => {
    if (data) {
      setPreviewStockLogo(data?.getStockExchangeById?.logoImage);
      setStockLogo(data?.getStockExchangeById?.logoImage);
      setBaseLanguage(data?.getStockExchangeById?.language);
      setTranslatedData(
        data?.getStockExchangeById?.multiLang?.map(data => ({
          description: data?.description,
          defaultClosureMessage: data?.defaultClosureMessage,
          defaultClosureTitle: data?.defaultClosureTitle,
          language: data?.language,
        })),
      );
      handleFieldChange({
        foundedDate: data?.getStockExchangeById?.foundedDate,
        ceo: data?.getStockExchangeById?.CEO,
        marketCap: data?.getStockExchangeById?.totalMarketCapitalization,
        code: data?.getStockExchangeById?.code,
        exchangeName: data?.getStockExchangeById?.stockExchangeName,
        countries: data?.getStockExchangeById?.countries?.map(
          data => `${data?.name};${data?.id}`,
        ),
        headQuarters: data?.getStockExchangeById?.headquarters,
        numberOfListings: data?.getStockExchangeById?.numberOfListings,
        website: data?.getStockExchangeById?.website,

        baseCurrency: data?.getStockExchangeById?.baseCurrencyCode,
        description: data?.getStockExchangeById?.description,
        defaultClosureMessage:
          data?.getStockExchangeById?.defaultClosureMessage,
        defaultClosureTitle: data?.getStockExchangeById?.defaultClosureTitle,
      });
      form.setFieldsValue({
        tradable: data?.getStockExchangeById?.tradeable,
        fractionalShares: data?.getStockExchangeById?.fractionalShares,
        orderExpirationLimit: data?.getStockExchangeById?.orderExpirationLimit,
      });
      setFees(
        data?.getStockExchangeById?.feeSchedule?.map(data => ({
          feeName: data?.feeName,
          feeType: data?.feeType,
          fixedFeeAmount: Number(data?.fixedFeeAmount),
          variableFeeAmount: Number(data?.variableFeeAmount),
        })),
      );
    }
  }, [data]);

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  const handleUpdate = values => {
    updateStockExchange({
      variables: {
        updateStockExchangeId: id,
        addStockExchangeInput: {
          logoImage: stockLogo,
          CEO: ceo,
          website,
          description,
          defaultClosureMessage,
          defaultClosureTitle,
          baseCurrencyCode: baseCurrency,
          headquarters: headQuarters,
          multiLang: translatedData,
          language: baseLanguage,
          orderExpirationLimit: Number(values?.orderExpirationLimit),
          code,
          tradeable: values.tradable != null ? values.tradable : false,
          fractionalShares:
            values.fractionalShares != null ? values.fractionalShares : false,
          feeSchedule: fees.map(data => ({
            feeName: data?.feeName,
            feeType: data?.feeType,
            fixedFeeAmount: Number(data?.fixedFeeAmount),
            variableFeeAmount: Number(data?.variableFeeAmount),
          })),
          numberOfListings: parseInt(numberOfListings),
          totalMarketCapitalization: marketCap.toString(),
          stockExchangeName: exchangeName,
          foundedDate: new Date(foundedDate).toISOString(),
          countriesOfOperation: countries?.map(data => data?.split(';')[1]),
        },
      },
      refetchQueries: [{query: GET_ALL_STOCKS}],
    })
      .then(({data: updateStockExchange}) => {
        if (updateStockExchange.__typename === 'Error') {
          toast.error(
            `Something went wrong!!! - ${updateStockExchange.message}`,
          );
        } else {
          toast.success('Stock Exchange Updated Successfully');
          navigate('/dashboard/stocks/listings');
        }
      })
      .catch(err => toast.error(`Something went wrong!! ${err}`));
  };

  if (loadingCountries) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div>
      {data ? (
        <></>
      ) : (
        <div className={styles.header}>
          <img onClick={() => navigate(-1)} src={backIcon} alt="" />
          <h1>Create Stock Exchange</h1>
        </div>
      )}
      <div className={styles.formSection}>
        <div className={styles.uploadImage}>
          <UploadImage
            label="Upload Logo"
            previewUrl={previewStockLogo}
            setImgUrl={setStockLogo}
            setPreviewUrl={setPreviewStockLogo}
            bucket={'campaign-bucket-images'}
          />
        </div>
        <Form
          form={form}
          onFinish={handleUpdate}
          className={styles.form}
          layout="vertical">
          <Form.Item
            label="Base Language"
            className={styles.bigWidth}
            name="baseLanguage"
            initialValue={data?.getStockExchangeById?.language}
            rules={[fieldRules('Language')]}>
            <Select
              value={baseLanguage}
              defaultValue={data?.getStockExchangeById?.language}
              onChange={e => setBaseLanguage(e)}
              placeholder="Select a base language">
              {defaultLanguages.map(data => (
                <Option key={data.key}>{data.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className={styles.bigWidth}
            label="Countries of Operations"
            name="countries"
            initialValue={data?.getStockExchangeById?.countries?.map(
              data => `${data?.name};${data?.id}`,
            )}>
            <Select
              value={countries}
              onChange={e => handleFieldChange({countries: e})}
              mode="multiple"
              defaultValue={data?.getStockExchangeById?.countriesOfOperation?.map(
                data => `${data?.name};${data?.id}`,
              )}
              showSearch>
              {getAllCountries?.getAllCountries.map(data => (
                <Option key={`${data?.name};${data?.id}`}>
                  <div className="select-option-style">
                    <img src={data?.icon} alt="" />
                    <span>{data?.name}</span>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Translation Language"
            name="translationLanguage"
            className={styles.bigWidth}>
            <Select
              value={selectedLanguage}
              onChange={e => setSelectedLanguage(e)}
              placeholder="Select a Language to be translated">
              {languageToBeTranslatedTo.map(name => (
                <Option key={name.key}>{name.label}</Option>
              ))}
            </Select>
          </Form.Item>
          <Tabs
            style={{width: 800}}
            tabBarExtraContent={
              selectedLanguage && (
                <Button
                  label="Translate"
                  disabled={loadingTranslation || !description}
                  onClick={handleTranslation}
                />
              )
            }>
            <Tabs.TabPane key="Original" tab="Original">
              <Form.Item
                initialValue={data?.getStockExchangeById?.description}
                label="Description"
                name="description">
                <Input.TextArea
                  value={description}
                  defaultValue={data?.getStockExchangeById?.description}
                  className={styles.bigWidth}
                  onChange={e =>
                    handleFieldChange({description: e.target.value})
                  }
                  rows={5}
                />
              </Form.Item>
              <Form.Item
                className={styles.bigWidth}
                label="Default Closure Title"
                name="defaultClosureTitle">
                <Input
                  defaultValue={data?.getStockExchangeById?.defaultClosureTitle}
                  value={defaultClosureTitle}
                  onChange={e =>
                    handleFieldChange({defaultClosureTitle: e.target.value})
                  }
                />
              </Form.Item>
              <Form.Item
                className={styles.bigWidth}
                label="Default Closure Message"
                initialValue={data?.getStockExchangeById?.defaultClosureMessage}
                name="defaultClosureMessage">
                <Input.TextArea
                  value={defaultClosureMessage}
                  defaultValue={
                    data?.getStockExchangeById?.defaultClosureMessage
                  }
                  className={styles.bigWidth}
                  onChange={e =>
                    handleFieldChange({defaultClosureMessage: e.target.value})
                  }
                  rows={5}
                />
              </Form.Item>
            </Tabs.TabPane>
            {translatedData?.map((item, index) => (
              <Tabs.TabPane
                key={index}
                tab={
                  languageToBeTranslatedTo.find(
                    lang => lang?.key === item?.language,
                  )?.label
                }>
                <Form.Item
                  className={styles.bigWidth}
                  label="Translated Description">
                  <Input.TextArea
                    value={item?.description}
                    onChange={e => handleChangeTranslation(index, e)}
                    rows={5}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.bigWidth}
                  label="Default Closure Title">
                  <Input
                    value={item?.defaultClosureTitle}
                    onChange={e => handleChangeTranslation(index, e)}
                  />
                </Form.Item>
                <Form.Item
                  className={styles.bigWidth}
                  label="Default Closure Message">
                  <Input.TextArea
                    value={item?.defaultClosureMessage}
                    onChange={e => handleChangeTranslation(index, e)}
                    rows={5}
                  />
                </Form.Item>
              </Tabs.TabPane>
            ))}
          </Tabs>
          <Form.Item
            label="Total Market Capital"
            name="marketCap"
            initialValue={
              data?.getStockExchangeById?.totalMarketCapitalization
            }>
            <InputNumber
              value={marketCap}
              onChange={e => handleFieldChange({marketCap: e})}
              placeholder="Enter the total Market Capital"
              className={styles.inputNumber}
              defaultValue={
                data?.getStockExchangeById?.totalMarketCapitalization
              }
              formatter={value =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={value => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Order Expiration Limit (Time in Days it takes an order to expire)"
            name="orderExpirationLimit">
            <Input type="number" />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Base Currency Code"
            name="baseCurrency"
            initialValue={data?.getStockExchangeById?.baseCurrencyCode}
            rules={[{required: true, message: 'Base Currency Code Required'}]}>
            <Select
              value={baseCurrency}
              defaultValue={data?.getStockExchangeById?.baseCurrencyCode}
              onChange={e => handleFieldChange({baseCurrency: e})}>
              {currencyCodes.map(({value}) => (
                <Option key={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'Code Required'}]}
            className={styles.inputWidth}
            initialValue={data?.getStockExchangeById?.code}
            label="Code"
            name="code">
            <Input
              value={code}
              defaultValue={data?.getStockExchangeById?.code}
              onChange={e => handleFieldChange({code: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Stock Exchange Name"
            rules={[
              {required: true, message: 'Stock Exchange Name is Required'},
            ]}
            initialValue={data?.getStockExchangeById?.stockExchangeName}
            name="name">
            <Input
              value={exchangeName}
              defaultValue={data?.getStockExchangeById?.stockExchangeName}
              onChange={e => handleFieldChange({exchangeName: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Headquarters"
            rules={[{required: true, message: 'Headquarters is Required'}]}
            initialValue={data?.getStockExchangeById?.headquarters}
            name="Headquarters">
            <Input
              value={headQuarters}
              defaultValue={data?.getStockExchangeById?.headquarters}
              onChange={e => handleFieldChange({headQuarters: e.target.value})}
            />
          </Form.Item>
          <Form.Item className={styles.inputWidth} label="CEO" name="ceo">
            <Input
              defaultValue={data?.getStockExchangeById?.CEO}
              value={ceo}
              onChange={e => handleFieldChange({ceo: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Number of Listings"
            initialValue={data?.getStockExchangeById?.numberOfListings}
            name="listingNumbers">
            <Input
              value={numberOfListings}
              defaultValue={data?.getStockExchangeById?.numberOfListings}
              type="number"
              onChange={e =>
                handleFieldChange({numberOfListings: e.target.value})
              }
            />
          </Form.Item>
          <Form.Item
            className={styles.inputWidth}
            label="Website"
            rules={[{type: 'url'}]}
            initialValue={data?.getStockExchangeById?.website}
            name="website">
            <Input
              value={website}
              defaultValue={data?.getStockExchangeById?.website}
              onChange={e => handleFieldChange({website: e.target.value})}
            />
          </Form.Item>
          <Form.Item
            label="Founded Date"
            name="date"
            rules={[{required: true, message: 'Founded Date is Required'}]}
            initialValue={
              data
                ? moment(data?.getStockExchangeById?.foundedDate, 'YYYY-MM-DD')
                : ''
            }>
            <DatePicker
              value={foundedDate}
              format={'YYYY-MM-DD'}
              defaultValue={
                data
                  ? moment(
                      data?.getStockExchangeById?.foundedDate,
                      'YYYY-MM-DD',
                    )
                  : ''
              }
              onChange={e =>
                handleFieldChange({
                  foundedDate: moment(e).format('YYYY-MM-DD'),
                })
              }
              className={styles.date}
            />
          </Form.Item>
          <OrderSettings
            existingData={data?.getStockExchangeById}
            fees={fees}
            handleChangeFees={setFees}
          />
          <SubmitButton
            disabled={loadingUpdate || !tabOperations?.update}
            label="Update"
          />
        </Form>
      </div>
    </div>
  );
};

export default UpdateStock;

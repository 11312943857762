import {useMutation, useQuery} from '@apollo/client';
import {Collapse, Spin, Switch} from 'antd';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {UPDATE_PROFILE} from 'graphql/mutations/user';
import {GET_USER_BY_ID} from 'graphql/queries/users';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import styles from './notificationPreference.module.scss';
import {GET_USER_NOTIFICATION_PREFERENCE} from 'graphql/queries/notifications';
import {UPDATE_USER_NOTIFICATION_PREFERENCE} from 'graphql/mutations/notifications';

const NotificationPreference = () => {
  const {id} = useParams();
  const [emailDailyNotification, setEmailDailyNotification] = useState(false);
  const [emailOtherNotifications, setEmailOtherNotifications] = useState(false);
  const [pushDailyNotification, setPushDailyNotification] = useState(false);
  const [pushOtherNotifications, setPushOtherNotifications] = useState(false);
  const [smsDailyNotification, setSmsDailyNotification] = useState(false);
  const [smsOtherNotifications, setSmsOtherNotifications] = useState(false);
  const [userPreference, setUserPreference] = useState([]);

  const {data, loading} = useQuery(GET_USER_BY_ID, {
    variables: {
      getUserByIdId: id,
    },
  });

  const {data: getUserPreference} = useQuery(GET_USER_NOTIFICATION_PREFERENCE, {
    variables: {
      input: {
        userId: id,
      },
    },
  });

  useEffect(() => {
    setEmailDailyNotification(
      data?.getUserById?.user?.notificationPreferences?.emailNotifications
        ?.receiveDailyInvestorUpdates,
    );
    setEmailOtherNotifications(
      data?.getUserById?.user?.notificationPreferences?.emailNotifications
        ?.receiveOtherEmailNotifications,
    );
    setPushDailyNotification(
      data?.getUserById?.user?.notificationPreferences?.pushNotifications
        ?.receiveDailyInvestorUpdates,
    );
    setPushOtherNotifications(
      data?.getUserById?.user?.notificationPreferences?.pushNotifications
        ?.receiveOtherPushNotifications,
    );
    setSmsDailyNotification(
      data?.getUserById?.user?.notificationPreferences?.smsNotifications
        ?.receiveDailyInvestorUpdates,
    );
    setSmsOtherNotifications(
      data?.getUserById?.user?.notificationPreferences?.smsNotifications
        ?.receiveOtherSmsNotifications,
    );
  }, [data]);

  useEffect(() => {
    setUserPreference(getUserPreference?.getUserNotificationPreferences?.data);
  }, [getUserPreference]);

  const [updateProfile, {loading: updateNotification}] =
    useMutation(UPDATE_PROFILE);
  const [updatePreference, {loading: updateNotificationPreference}] =
    useMutation(UPDATE_USER_NOTIFICATION_PREFERENCE);

  const handlePreferenceChange = (index, i, e) => {
    setUserPreference(prevPreferences => {
      const newValues = [...prevPreferences];
      const channels = [...newValues[index].channels];
      channels[i] = {...channels[i], enabled: e};
      newValues[index] = {...newValues[index], channels};
      return newValues;
    });
  };

  const {Panel} = Collapse;

  const handleUpdate = () => {
    updateProfile({
      variables: {
        data: {
          notificationPreferences: {
            emailNotifications: {
              receiveDailyInvestorUpdates: emailDailyNotification,
              receiveOtherEmailNotifications: emailOtherNotifications,
            },
            pushNotifications: {
              receiveDailyInvestorUpdates: pushDailyNotification,
              receiveOtherPushNotifications: pushOtherNotifications,
            },
            smsNotifications: {
              receiveDailyInvestorUpdates: smsDailyNotification,
              receiveOtherSmsNotifications: smsOtherNotifications,
            },
          },
          userId: id,
        },
      },
    }).then(({data: updateProfile}) => {
      updatePreference({
        variables: {
          input: {
            preferences: userPreference,
            userId: id,
          },
        },
      }).then(({data: updateUserNotificationPreferences}) => {
        if (
          updateProfile.__typename === 'Error' ||
          updateUserNotificationPreferences.__typename === 'Error'
        ) {
          toast.error(updateProfile.message);
        } else {
          toast.success('Notification preference updated');
        }
      });
    });
  };

  return loading ? (
    <div className={styles.loading}>
      <Spin size="large" />
      <h1>Loading...</h1>
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Notification Preference</h1>
      </div>
      <div className={styles.row}>
        <Collapse defaultActiveKey={['1']} ghost>
          {userPreference?.map((item, index) => (
            <Panel
              header={`${item?.name} Notification`}
              key={index}
              className={styles.pannel}
              style={{color: '#16054e'}}>
              {item?.channels?.map((channel, i) => (
                <div key={`${i}-${channel?.key}`} className={styles.switches}>
                  <div className={styles['form-group']}>
                    <label>Receive {channel?.name} Notification: </label>
                    <Switch
                      checked={channel?.enabled}
                      onChange={e => handlePreferenceChange(index, i, e)}
                    />
                  </div>
                  <hr />
                </div>
              ))}
            </Panel>
          ))}
        </Collapse>
      </div>
      <SubmitButton
        handleClick={handleUpdate}
        disabled={updateNotification || updateNotificationPreference}
        label="Update Preference"
      />
    </div>
  );
};

export {NotificationPreference};

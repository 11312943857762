// these packages are not installed in this project, but are required
import {configureStore} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

import app from './app';
import auth from './auth';
import watchlist from './watchlist';
import company from './company';
import campaign from './campaign';
import news from './news';
import savings from './savings';

const reducer = combineReducers({
  auth,
  app,
  watchlist,
  company,
  campaign,
  savings,
  news,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  // you might not have to worry about middleware, thunk is already provided by default for redux toolkit configureStore
  // https://redux-toolkit.js.org/api/getDefaultMiddleware
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;

import {Route, Routes} from 'react-router-dom';
import {AllRewardSchemes} from './rewardSchemeTabs/allRewardSchemes';
import {EditRewardScheme} from './rewardSchemeTabs/update/tabs/editRewardSchemes';
import {NewRewardScheme} from './rewardSchemeTabs/newRewardScheme';
import {ReferralRewardSchemes} from './rewardSchemeTabs/referralRewardSchemes';
import {RewardSchemeManagement} from './index';
import {TransactionalRewardSchemes} from './rewardSchemeTabs/transactionalRewardSchemes';
import {ExpiredRewardSchemes} from './rewardSchemeTabs/expiredRewardSchemes';
import {UserReward} from './rewardSchemeTabs/update/tabs/userReward';
import {UpdateRewardSchemeManagement} from './rewardSchemeTabs/update';
import {NewUserRewardScheme} from './rewardSchemeTabs/userRewardGiveout';
import {AllUserReward} from './rewardSchemeTabs/userReward';
import LanguageVersion from './rewardSchemeTabs/update/languageVersion';

const RewardSchemes = () => {
  return (
    <Routes>
      <Route path="/" element={<RewardSchemeManagement />}>
        <Route index path="/" element={<AllRewardSchemes />} />
        <Route path="/expired" element={<ExpiredRewardSchemes />} />
        <Route path="/referral" element={<ReferralRewardSchemes />} />
        <Route path="/transactional" element={<TransactionalRewardSchemes />} />
        <Route path="/users" element={<AllUserReward />} />
      </Route>
      <Route
        path="/update/:id/:language"
        element={<UpdateRewardSchemeManagement />}>
        <Route index element={<EditRewardScheme />} />
        <Route path="users" element={<UserReward />} />
        <Route />
      </Route>
      <Route path="/new-user-reward" element={<NewUserRewardScheme />} />
      <Route
        path="/update/language-version/:id"
        element={<LanguageVersion />}
      />
      <Route path="/new" element={<NewRewardScheme />} />
    </Routes>
  );
};

export {RewardSchemes};

import styles from './nav.module.scss';
import Logo from '../logo/logo';
import {useNavigate} from 'react-router-dom';
import Menu from '../menu/menu';
import CustomLink from '../customLink/customLink';
import classNames from 'classnames/bind';
import {useDispatch, useSelector} from 'react-redux';
import {logout} from 'redux/store/auth';
import {ADMIN_TABS} from 'utils/constants';

const renderNavItems = (tabs, availableTabs) => {
  return Object.keys(tabs).map(key => {
    const tab = tabs[key];
    if (tab.inner) {
      // If tab has inner objects, recursively render them
      const innerTabs = Object.keys(tab.inner)
        .map(innerKey => tab.inner[innerKey])
        .filter(innerTab => availableTabs.includes(innerTab.tab));

      if (innerTabs.length === 0) {
        return null; // If no inner tabs are accessible, don't render the menu
      }

      return (
        <Menu key={key} icon={tab.icon} name={tab.name}>
          {innerTabs.map(innerTab => (
            <div key={innerTab.name}>
              <CustomLink
                icon={innerTab.icon}
                name={innerTab.name}
                to={innerTab.to}
              />
            </div>
          ))}
        </Menu>
      );
    } else if (availableTabs.includes(tab.tab)) {
      // If tab is accessible to the user, render CustomLink
      return (
        <li key={key}>
          <CustomLink icon={tab.icon} name={tab.name} to={tab.to} />
        </li>
      );
    } else {
      // If tab is not accessible to the user, render nothing
      return null;
    }
  });
};

const Nav = ({isOpen, className}) => {
  const navigation = useNavigate();
  const cx = classNames.bind(styles);
  const dispatch = useDispatch();
  const role = useSelector(state => state?.auth?.userData?.user);

  const handleLogout = () => {
    dispatch(logout());
    navigation('/');
  };

  const extractTabs = permissions => {
    let availableTabs = [];

    const extractInnerTabs = tabs => {
      Object.keys(tabs).forEach(key => {
        const tab = tabs[key];
        availableTabs.push(tab.tab);
        if (tab.inner) {
          extractInnerTabs(tab.inner);
        }
      });
    };

    if (['SUPERADMIN', 'ADMIN'].includes(role?.roleSettings?.name)) {
      // If no permissions or tabs are specified, return all tabs
      extractInnerTabs(ADMIN_TABS);
    } else {
      // Extract tab values from the provided permissions
      permissions.tabs.forEach(permission => {
        if (permission.tab) {
          availableTabs.push(permission.tab);
        }
      });
    }

    return availableTabs;
  };

  if (!role?.roleSettings)
    return (
      <nav className={cx('nav', className, isOpen ? 'show' : '')} id="navbar">
        <h2 style={{color: 'white', textAlign: 'center'}}>
          No TAB Access Granted, Please make sure this is an ADMIN
        </h2>
        <button className={styles.logout} onClick={() => navigation('/')}>
          Log out
        </button>
      </nav>
    );
  const availableTabs = extractTabs(role?.roleSettings);
  return (
    <nav id="navbar" className={cx('nav', className, isOpen ? 'show' : '')}>
      <div className={cx('logo')}>
        <Logo onClick={() => navigation('')} />
      </div>

      <ul className={cx('navLinks')}>
        {renderNavItems(ADMIN_TABS, availableTabs || [])}
      </ul>
      <div>
        <button onClick={handleLogout} className={styles.logout}>
          Log out
        </button>
      </div>
    </nav>
  );
};

export {Nav};

import {Form, Select} from 'antd';
import {useEffect, useState} from 'react';
import styles from './pushNotificationData.module.scss';

const PushNotificationData = ({
  data,
  type,
  handleReportMetaData,
  handleOpportunityMetaData,
  handleCompanyMetaData,
  handleGroupMetaData,
}) => {
  const [companyId, setCompanyId] = useState('');
  const [opportunityId, setOpportunityId] = useState('');
  const [reportId, setReportId] = useState('');
  const [groupId, setGroupId] = useState('');

  useEffect(() => {
    switch (type) {
      case 'COMPANY_BY_ID': {
        const company = data?.getAllCompanies?.filter(
          data => data?.id === companyId,
        );
        handleCompanyMetaData({
          companyId: company[0]?.id,
          companyName: company[0]?.name,
          imageUrl: company[0]?.logoImgURL,
          type: 'COMPANY_BY_ID',
        });
        handleGroupMetaData({});
        handleReportMetaData({});
        handleOpportunityMetaData({});
        break;
      }
      case 'SINGLE_STOCK': {
        const company = data?.getAllCompanies?.filter(
          data => data?.id === companyId,
        );
        handleCompanyMetaData({
          companyId: company?.[0]?.id,
          companyName: company?.[0]?.name,
          imageUrl: company?.[0]?.logoImgURL,
          type: 'SINGLE_STOCK',
        });
        handleGroupMetaData({});
        handleReportMetaData({});
        handleOpportunityMetaData({});
        break;
      }
      case 'REPORT_BY_ID': {
        const report = data?.getAllReports?.filter?.(
          data => data?.id === reportId,
        );
        handleReportMetaData({
          documentLink: report[0]?.document,
          reportId: report[0]?.id,
          reportType: report[0]?.type,
          title: report[0]?.title,
          type: 'REPORT_BY_ID',
        });
        handleGroupMetaData({});
        handleOpportunityMetaData({});
        handleCompanyMetaData({});
        break;
      }
      case 'OPPORTUNITY_BY_ID': {
        const opportunity = data?.getAllCampaigns?.campaigns?.filter(
          data => data?.id === opportunityId,
        );
        handleOpportunityMetaData({
          campaignId: opportunity[0]?.id,
          campaignMode: opportunity[0]?.campaignMode,
          companyName: opportunity[0]?.fundraisingCompany?.companyName,
          listingType: opportunity[0]?.listingType,
          type: 'OPPORTUNITY_BY_ID',
        });
        handleGroupMetaData({});
        handleReportMetaData({});
        handleCompanyMetaData({});
        break;
      }
      case 'GROUP_BY_ID': {
        const group = data?.getAllGroups?.groups?.filter(
          data => data?.id === groupId,
        );
        handleGroupMetaData({
          groupId: group[0]?.id,
          groupName: group[0]?.name,
          imageUrl: group[0]?.bannerImg,
          type: 'GROUP_BY_ID',
        });
        handleOpportunityMetaData({});
        handleCompanyMetaData({});
        handleReportMetaData({});
      }
    }
  }, [companyId, opportunityId, reportId, groupId, type]);

  return (
    <div>
      {['COMPANY_BY_ID', 'SINGLE_STOCK'].includes(type) && (
        <Form.Item
          rules={[
            {
              required: ['COMPANY_BY_ID', 'SINGLE_STOCK'].includes(type),
              message: 'Selecting a Company is Required',
            },
          ]}
          name="company"
          label="Company">
          <Select
            style={{width: 730}}
            showSearch
            value={companyId}
            onChange={e => setCompanyId(e)}
            placeholder="Select a Company"
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().includes(inputValue.toLowerCase())
            }>
            {data?.getAllCompanies?.map(data => (
              <Select.Option key={data?.id}>{data?.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {type === 'GROUP_BY_ID' && (
        <Form.Item name="group" label="Group">
          <Select
            style={{width: 730}}
            showSearch
            value={groupId}
            onChange={e => setGroupId(e)}
            placeholder="Select a Group"
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().includes(inputValue.toLowerCase())
            }>
            {data?.getAllGroups?.groups?.map(data => (
              <Select.Option key={data?.id}>{data?.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {type === 'REPORT_BY_ID' && (
        <Form.Item name="report" label="Reports">
          <Select
            style={{width: 730}}
            value={reportId}
            onChange={e => setReportId(e)}
            showSearch
            placeholder="Select a Report"
            filterOption={(inputValue, option) =>
              option.children.toLowerCase().includes(inputValue.toLowerCase())
            }>
            {data?.getAllReports?.map(data => (
              <Select.Option key={data?.id}>{data?.title}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
      {type === 'OPPORTUNITY_BY_ID' && (
        <Form.Item name="opportunity" label="Opportunity">
          <Select
            style={{width: 730}}
            showSearch
            value={opportunityId}
            onChange={e => setOpportunityId(e)}
            placeholder="Select a Opportunity">
            {data?.getAllCampaigns?.campaigns
              ?.filter(
                data =>
                  data?.campaignMode === 'LIVE' &&
                  data?.campaignStatus !== 'DRAFT',
              )
              ?.map(data => (
                <Select.Option
                  key={data?.id}
                  // eslint-disable-next-line react/no-children-prop
                  children={
                    <div className={styles['option-data']}>
                      <div className={styles['name-data']}>
                        <img
                          src={data?.fundraisingCompany?.logoImgURL}
                          alt=""
                        />
                        <span>{data?.fundraisingCompany?.companyName}</span>
                      </div>
                      <div>--{data?.campaignStatus}</div>
                    </div>
                  }
                />
              ))}
          </Select>
        </Form.Item>
      )}
    </div>
  );
};

export default PushNotificationData;
